import { Button } from '@boltenergy-be/design-system'
import Modal from 'components/Modal/Modal'
import SearchBar from 'components/Search/SearchBar'
import SelectProducerCard from 'features/contracts/add/components/SelectProducerCard/SelectProducerCard'
import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Producer, ProducerEnergyType } from 'types/types'
import { normalizeText } from 'utils/format'
import styles from './SelectProducerModal.module.scss'
import { SelectProducerModalProps as Props } from './types'
import { useStoreSelector } from 'hooks/store'
import { useGetProducersQuery } from 'store/queries/bolt-api'
import LoadingDots from 'components/LoadingDots/LoadingDots'

const SelectProducerModal: FC<Props> = ({ isOpen, setClose, addContractData, setAddContractData }) => {
  // REDUX STORE
  const { language } = useStoreSelector((store) => store.app)

  // Redux queries
  const { data, isLoading } = useGetProducersQuery()

  // i18n
  const { t } = useTranslation()

  // Local state
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [shownProducers, setShownProducers] = useState<number>(9)

  /**
   * Update producer in registration data state object
   * @param {Producer} producer
   */
  const handleSelectProducer = (producer: Producer) => {
    setAddContractData &&
      setAddContractData({
        ...addContractData,
        producer
      })
    setClose()
  }

  // Memoized constants
  const searchableProducers = useMemo<Producer[]>(() => {
    const normalizedQuery = normalizeText(searchQuery)
    const filteredProducers = data?.producers.filter((p) => p.enabled && !p.isSoldOut) || []

    return filteredProducers.filter(
      (p) =>
        normalizeText(p?.name[language])?.includes(normalizedQuery) ||
        normalizeText(p?.location)?.includes(normalizedQuery) ||
        normalizeText(t(`energyType.${p.energyType || ProducerEnergyType.WIND}`, { ns: 'producer' }))?.includes(normalizedQuery)
    )
  }, [language, data, searchQuery, t])

  return (
    <Modal {...{ isOpen, setClose }} className={styles.container}>
      <div className={styles.header}>
        <h2>{t('add.steps.producer.chooseProducerModal.title', { ns: 'contracts' })}</h2>
        <p>{t('add.steps.producer.chooseProducerModal.description', { ns: 'contracts' })}</p>

        <SearchBar
          disabled={isLoading}
          size="small"
          className={styles.search}
          placeholder={t('add.steps.producer.chooseProducerModal.searchPlaceholder', { ns: 'contracts' })}
          onInput={(value) => setSearchQuery(value)}
        />
      </div>
      <div className={styles.content}>
        {isLoading ? (
          <LoadingDots />
        ) : (
          <div className={styles.grid}>
            {searchableProducers.slice(0, shownProducers).map((producer) => {
              return (
                <SelectProducerCard
                  key={producer.id}
                  producer={producer}
                  activeProducerId={addContractData.producer?.id || ''}
                  handleSelectProducer={handleSelectProducer}
                />
              )
            })}
            {shownProducers < searchableProducers.length && (
              <div className={styles['btn-container']}>
                <Button size="small" onClick={() => setShownProducers(shownProducers + 3)}>
                  {t('add.steps.producer.chooseProducerModal.showMore', { ns: 'contracts' })}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </Modal>
  )
}

export default SelectProducerModal
