import { imageMiniGeneratorFr, imageMiniGeneratorNl } from 'assets/images/year-overview'
import { referralDefaultImg } from 'assets/images/referral'
import {
  Avocado,
  Diesel,
  Gas,
  InfoOutlined,
  Microwave,
  Night,
  Phone,
  Shower,
  Steak,
  Thermostat,
  Trees,
  Tv,
  Vacation,
  VacuumCleaner,
  VegetarianMeal,
  WashingMachine
} from 'assets/svg'
import classNames from 'classnames'
import ComparisonCard from 'components/EnergyOverview/ComparisonCard/ComparisonCard'
import GasTipCard from 'components/EnergyOverview/GasTipCard/GasTipCard'
import InfoModal from 'components/EnergyOverview/InfoModal/InfoModal'
import { PersonalProps as Props } from 'components/EnergyOverview/types'
import EnergyOverviewSlide from 'components/Slider/EnergyOverviewSlide/EnergyOverviewSlide'
import { BrandColors } from 'components/Slider/EnergyOverviewSlide/types'
import EnergyOverviewCarousel from 'components/Slider/EnergyOverviewSlider/EnergyOverviewCarousel'
import ThanksSlide from 'components/Slider/ThanksSlide/ThanksSlide'
import VerticalSlider from 'components/Slider/VerticalSlider/VerticalSlider'
import SmallProducerCard from 'components/SmallProducerCard/SmallProducerCard'
import { DATE_FORMAT_LONG } from 'constants/constants'
import dayjs from 'dayjs'
import parse from 'html-react-parser'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Language } from 'store/app/types'
import { Store } from 'store/types'
import { formatNumber } from 'utils/format'
import styles from './EnergyOverviewSection.module.scss'
import Card from 'components/Card/Card.tsx'
import Link from 'components/Link/Link.tsx'

const SectionPersonal: FC<Props> = ({ data, communityReferrals, openFeedbackWidget }) => {
  // REDUX STORE
  const { language } = useSelector((store: Store) => store.app)
  const { producer, info } = useSelector((store: Store) => store.user)

  // i18n
  const { t } = useTranslation()

  // Local state
  const [infoModalOpen, setInfoModalOpen] = useState<boolean>(false)
  const [confetti, setConfetti] = useState<boolean>(false)

  // Constants
  const { electricity, gas, referrals } = data

  return (
    <>
      <Card.Title>{t('personal.title', { ns: 'energyOverview' })}</Card.Title>

      <EnergyOverviewCarousel openFeedbackWidget={() => openFeedbackWidget(true)} triggerConfetti={(value) => setConfetti(value)}>
        {/* CONSUMED ELECTRICITY SLIDE */}
        {electricity?.consumed?.total && producer && (
          <EnergyOverviewSlide
            data={formatNumber(electricity.consumed.total)}
            label={t('personal.electricityProducer.label', { ns: 'energyOverview' })}
            metric="kWh"
            description={t('personal.electricityProducer.description', {
              producer: (producer.name && producer.name[language]) ?? '',
              from: dayjs(electricity.consumed.from).locale(language).format(DATE_FORMAT_LONG),
              to: dayjs(electricity.consumed.to).locale(language).format(DATE_FORMAT_LONG),
              ns: 'energyOverview'
            })}
          >
            {producer?.images && (
              <img
                src={producer.images.profile?.url ? producer.images.profile?.url : producer.images.header?.url}
                alt={t('alt', '', {
                  producer: (producer.name && producer.name[language]) ?? '',
                  ns: 'producer'
                })}
              />
            )}
            <SmallProducerCard
              className={styles['small-card']}
              energyType={producer.energyType}
              location={producer.location}
              title={(producer.name && producer.name[language]) ?? ''}
            />
          </EnergyOverviewSlide>
        )}

        {/* CONSUMED ELECTRICITY COMPARISON SLIDE */}
        {electricity?.consumed?.total && (
          <EnergyOverviewSlide
            data={formatNumber(electricity.consumed.total)}
            label={t('personal.electricityComparison.label', { ns: 'energyOverview' })}
            metric="kWh"
            description={t('personal.electricityComparison.description', {
              phone: formatNumber(electricity.consumed.amountOfPhoneCharges),
              tv: formatNumber(electricity.consumed.tvHours),
              ns: 'energyOverview'
            })}
          >
            <VerticalSlider>
              <ComparisonCard
                data={formatNumber(electricity.consumed.microwaveHours)}
                description={t('personal.electricityComparison.microwave', { ns: 'energyOverview' })}
                icon={<Microwave hasBackdrop />}
              />
              <ComparisonCard
                data={formatNumber(electricity.consumed.amountOfPhoneCharges)}
                description={t('personal.electricityComparison.phone', { ns: 'energyOverview' })}
                icon={<Phone hasBackdrop />}
              />
              <ComparisonCard
                data={formatNumber(electricity.consumed.laundryMachines)}
                description={t('personal.electricityComparison.laundry', { ns: 'energyOverview' })}
                icon={<WashingMachine hasBackdrop />}
              />
              <ComparisonCard
                data={formatNumber(electricity.consumed.tvHours)}
                description={t('personal.electricityComparison.tv', { ns: 'energyOverview' })}
                icon={<Tv hasBackdrop />}
              />
              <ComparisonCard
                data={formatNumber(electricity.consumed.vacuumHours)}
                description={t('personal.electricityComparison.vacuum', { ns: 'energyOverview' })}
                icon={<VacuumCleaner hasBackdrop />}
              />
            </VerticalSlider>
          </EnergyOverviewSlide>
        )}

        {/* CARBON DIOXIDE AVERAGE SLIDE */}
        {electricity?.carbonDioxide?.reduction && (
          <EnergyOverviewSlide
            color={BrandColors.BLUE}
            data={`-${formatNumber(electricity.carbonDioxide.reduction)}`}
            label={t('personal.carbonDioxideAverage.label', { ns: 'energyOverview' })}
            metric={t('personal.carbonDioxideAverage.metric', { ns: 'energyOverview' })}
            description={t('personal.carbonDioxideAverage.description', { ns: 'energyOverview' })}
          >
            <div className={classNames(styles.graph, styles['graph-emissions-personal'])}>
              <data
                value={100 / 7.5}
                className={classNames(styles.you, styles['with-bolt-icon'], { [styles.fr]: language === Language.FR })}
              />
              <data value={100} className={classNames(styles.average, { [styles.fr]: language === Language.FR })} />
            </div>
          </EnergyOverviewSlide>
        )}

        {/* CARBON DIOXIDE COMPARISON SLIDE */}
        {electricity?.carbonDioxide?.reduction && (
          <EnergyOverviewSlide
            color={BrandColors.BLUE}
            data={`-${formatNumber(electricity.carbonDioxide.reduction)}`}
            label={t('personal.carbonDioxideComparison.label', { ns: 'energyOverview' })}
            metric={t('personal.carbonDioxideComparison.metric', { ns: 'energyOverview' })}
            description={t('personal.carbonDioxideComparison.description', { ns: 'energyOverview' })}
          >
            <VerticalSlider>
              <ComparisonCard
                data={formatNumber(electricity.carbonDioxide.dieselKilometers)}
                description={t('personal.carbonDioxideComparison.diesel', { ns: 'energyOverview' })}
                icon={<Diesel hasBackdrop />}
              />
              <ComparisonCard
                data={formatNumber(electricity.carbonDioxide.trees)}
                description={t('personal.carbonDioxideComparison.trees', { ns: 'energyOverview' })}
                icon={<Trees hasBackdrop />}
              />
              <ComparisonCard
                data={formatNumber(electricity.carbonDioxide.veggieDays)}
                description={t('personal.carbonDioxideComparison.veggie', { ns: 'energyOverview' })}
                icon={<VegetarianMeal hasBackdrop />}
              />
              <ComparisonCard
                data={formatNumber(electricity.carbonDioxide.avocados)}
                description={t('personal.carbonDioxideComparison.avocado', { ns: 'energyOverview' })}
                icon={<Avocado hasBackdrop />}
              />
              <ComparisonCard
                data={formatNumber(electricity.carbonDioxide.steaks)}
                description={t('personal.carbonDioxideComparison.steak', { ns: 'energyOverview' })}
                icon={<Steak hasBackdrop />}
              />
            </VerticalSlider>
          </EnergyOverviewSlide>
        )}

        {/* GAS SLIDE */}
        {gas?.consumed?.total && (
          <EnergyOverviewSlide
            color={BrandColors.PURPLE}
            data={formatNumber(gas.consumed.total)}
            label={t('personal.gas.label', { ns: 'energyOverview' })}
            metric="kWh"
            description={t('personal.gas.description', {
              from: dayjs(gas.consumed.from).locale(language).format(DATE_FORMAT_LONG),
              to: dayjs(gas.consumed.to).locale(language).format(DATE_FORMAT_LONG),
              ns: 'energyOverview'
            })}
          >
            <VerticalSlider duration={30000} reverse>
              <GasTipCard
                icon={<Thermostat hasBackdrop />}
                tip={t('personal.gas.tips.temperature', { ns: 'energyOverview' })}
                orderNumber={1}
              />
              <GasTipCard icon={<Shower hasBackdrop />} tip={t('personal.gas.tips.shower', { ns: 'energyOverview' })} orderNumber={2} />
              <GasTipCard icon={<Gas hasBackdrop />} tip={t('personal.gas.tips.heating', { ns: 'energyOverview' })} orderNumber={3} />
              <GasTipCard icon={<Night hasBackdrop />} tip={t('personal.gas.tips.night', { ns: 'energyOverview' })} orderNumber={4} />
              <GasTipCard icon={<Vacation hasBackdrop />} tip={t('personal.gas.tips.away', { ns: 'energyOverview' })} orderNumber={5} />
            </VerticalSlider>

            {/* INFO MODAL TRIGGER */}
            <button className={styles['info-button']} aria-label="info" onClick={() => setInfoModalOpen(true)}>
              <InfoOutlined />
            </button>

            {/* INFO MODAL */}
            <InfoModal isOpen={infoModalOpen} closeFn={() => setInfoModalOpen(false)}>
              {parse(t('personal.gas.modalText', { ns: 'energyOverview' }))}
            </InfoModal>
          </EnergyOverviewSlide>
        )}

        {/* MINI GENERATORS SLIDE */}
        {electricity?.produced?.total && (
          <EnergyOverviewSlide
            color={BrandColors.ORANGE}
            data={formatNumber(electricity.produced.total)}
            label={t('personal.miniGenerators.label', { ns: 'energyOverview' })}
            metric="kWh"
            description={t('personal.miniGenerators.description', {
              from: dayjs(electricity.produced.from).locale(language).format(DATE_FORMAT_LONG),
              to: dayjs(electricity.produced.to).locale(language).format(DATE_FORMAT_LONG),
              ns: 'energyOverview'
            })}
          >
            <figure className={styles['mini-figure']}>
              <img
                src={language === Language.FR ? imageMiniGeneratorFr : imageMiniGeneratorNl}
                alt={t('personal.miniGenerators.imageAlt', { ns: 'energyOverview' })}
              />
            </figure>
          </EnergyOverviewSlide>
        )}

        {/* REFERRALS SLIDE */}
        {(referrals?.amount || communityReferrals) && (
          <EnergyOverviewSlide
            color={BrandColors.PINK}
            data={formatNumber(referrals?.amount || communityReferrals)}
            label={t(`${!referrals?.amount ? 'personal' : 'community'}.referrals.label`, { ns: 'energyOverview' })}
            description={t(`${referrals?.amount ? 'personal' : 'community'}.referrals.description`, {
              amount: referrals?.amount || communityReferrals,
              ns: 'energyOverview'
            })}
          >
            <img
              src={referralDefaultImg}
              className={styles['referrals-image']}
              alt={t('personal.referrals.imageAlt', { ns: 'energyOverview' })}
            />
            <div className={styles['referrals-container']}>
              <Link
                as="a"
                representation="button"
                href={
                  language === Language.FR
                    ? `https://www.boltenergie.be/fr/convaincre-un-ami${referrals?.code ? `?generatedReferralCode=${referrals?.code}` : ''}`
                    : `https://www.boltenergie.be/nl/overtuig-een-vriend${
                        referrals?.code ? `?generatedReferralCode=${referrals.code}` : ''
                      }`
                }
                rel="noreferrer noopener"
                target="_blank"
              >
                {t(`${referrals?.amount ? 'personal' : 'community'}.referrals.button`, { ns: 'energyOverview' })}
              </Link>
            </div>
          </EnergyOverviewSlide>
        )}

        {/* THANKS SLIDE */}
        <ThanksSlide popConfetti={confetti} name={info.firstName} text={t('personal.thanks.text', { ns: 'energyOverview' })} />
      </EnergyOverviewCarousel>
    </>
  )
}

export default SectionPersonal
