import { Language } from 'store/app/types'
import {
  AddContractData,
  AddContractSteps,
  CounterTypes,
  EanCaptureType,
  InvoiceMedium,
  LegalFormTranslations,
  MeterType,
  PaymentMethod,
  SimulationType,
  Situation
} from 'features/contracts/add/types'
import { Customer, LegalForm, LegalFormSelectOptionKeys, SalesforceLegalForm } from 'types/types'
import { dateStringToObject } from 'utils/addContract'
import { store } from 'store'
import { getMemoizedSelectedContracts } from 'utils/contracts'
import { UserTypes } from 'store/auth/types'
import { Region } from 'api/types'
import { ELProduct } from 'types/products'
import { defineProducts } from 'utils/products'

export const GET_INITIAL_ADD_CONTRACT_DATA = (language: Language, currentCustomer?: Customer): AddContractData => {
  // Redux
  const { selectedContracts } = store.getState().user
  const { userType } = store.getState().auth

  // Constants
  const isSales = userType === UserTypes.SALES
  const contracts = !isSales ? getMemoizedSelectedContracts(selectedContracts) : undefined
  const invoiceMedium = contracts?.billingContract?.deliveryMode?.toLowerCase() === 'email' ? InvoiceMedium.DIGITAL : InvoiceMedium.PAPER
  const elProduct = contracts?.electricityContract?.detail?.productCode || defineProducts(invoiceMedium, false).electricity

  return {
    [AddContractSteps.ADDRESS]: {
      deliveryAddress: {
        countryCode: 'BE',
        postalCode: '',
        townCode: 0,
        townName: '',
        streetName: '',
        streetNumber: '',
        streetBox: ''
      },
      needsGas: false,
      region: Region.FLANDERS
    },
    [AddContractSteps.PRODUCER]: undefined,
    [AddContractSteps.PRODUCT]: {
      electricity: elProduct as ELProduct,
      gas: undefined
    },
    [AddContractSteps.INSTALMENT_DATA]: {
      simulationType: SimulationType.EAV,
      instalments: {
        electricity: 0
      }
    },
    [AddContractSteps.METER_DETAILS]: {
      contractStartDate: null,
      counterType: CounterTypes.ANALOG,
      eanCaptureType: EanCaptureType.NO_LOOKUP_DONE,
      electricity: {
        total: null,
        day: null,
        night: null,
        exclNight: null,
        ean: '',
        eanExclNight: null,
        meterNumber: null
      },
      hasExclNight: false,
      hasExclNightEan: true,
      hasSolarPanels: false,
      meterReadingMonth: null,
      meterType: MeterType.DOUBLE_RATE,
      situation: Situation.SWITCH,
      dynamicTariff: false
    },
    [AddContractSteps.CUSTOMER_DATA]: {
      customerNumber: currentCustomer?.id,
      isCompany: !!currentCustomer?.company?.enterpriseNumber || false,
      ...(!!currentCustomer?.company?.enterpriseNumber && {
        companyNumber: currentCustomer.company.enterpriseNumber,
        legalForm: currentCustomer.company.legalForm,
        companyName: currentCustomer.company.name,
        subjectToVat: currentCustomer.company.vatApplication
      }),
      firstName: currentCustomer?.person?.firstName || '',
      lastName: currentCustomer?.person?.lastName || '',
      email: currentCustomer?.contact?.email || '',
      emailConfirmation: currentCustomer?.contact?.email || '',
      mobilePhone: currentCustomer?.contact?.phoneMobile || '',
      dateOfBirth: currentCustomer?.person?.birthDate
        ? dateStringToObject(currentCustomer.person.birthDate)
        : {
            day: '',
            month: '',
            year: ''
          },
      language,
      sameCorrespondenceAddress: true,
      correspondenceAddress: currentCustomer?.address || undefined,
      invoiceMedium: contracts?.billingContract?.deliveryMode?.toLowerCase() === 'email' ? InvoiceMedium.DIGITAL : InvoiceMedium.PAPER,
      paymentMethod: currentCustomer?.paymentDetails?.directDebit ? PaymentMethod.DIRECT_DEBIT : PaymentMethod.BANK_TRANSFER,
      iban: currentCustomer?.paymentDetails?.iban || undefined
    }
  }
}

export const ADD_CONTRACT_FLOW = [
  AddContractSteps.ADDRESS,
  AddContractSteps.PRODUCER,
  AddContractSteps.PRODUCT,
  AddContractSteps.INSTALMENT_DATA,
  AddContractSteps.METER_DETAILS,
  AddContractSteps.CUSTOMER_DATA,
  AddContractSteps.CONFIRMATION,
  AddContractSteps.DONE
]

export const MOVE_CONTRACT_FLOW = [
  AddContractSteps.ADDRESS,
  AddContractSteps.REFERRAL,
  AddContractSteps.PRODUCER,
  AddContractSteps.PRODUCT,
  AddContractSteps.INSTALMENT_DATA,
  AddContractSteps.METER_DETAILS,
  AddContractSteps.CUSTOMER_DATA,
  AddContractSteps.CONFIRMATION,
  AddContractSteps.DONE
]

export const MIN_AGE = 18

export const DAYS_TO_YEARS_COEFFICIENT = 0.002738

/**
 * This is the old mapping of the legal form translations, where there was a key per nl and fr value
 * In the new enum (which is used in Salesforce & is the new way), there is a combined nl/fr key for each legal form
 */
export const OLD_LEGAL_FORM_TRANSLATIONS: Record<LegalFormSelectOptionKeys, LegalFormTranslations> = {
  [LegalFormSelectOptionKeys.BVBA]: {
    nl: LegalForm.BVBA,
    fr: LegalForm.SPRL
  },
  [LegalFormSelectOptionKeys.BV]: {
    nl: LegalForm.BV,
    fr: LegalForm.SRL
  },
  [LegalFormSelectOptionKeys.NV]: {
    nl: LegalForm.NV,
    fr: LegalForm.SA
  },
  [LegalFormSelectOptionKeys.GCV]: {
    nl: LegalForm.GCV,
    fr: LegalForm.SCS
  },
  [LegalFormSelectOptionKeys.CVBA]: {
    nl: LegalForm.CVBA,
    fr: LegalForm.SCRL
  },
  [LegalFormSelectOptionKeys.VZW]: {
    nl: LegalForm.VZW,
    fr: LegalForm.ASBL
  },
  [LegalFormSelectOptionKeys.EBVBA]: {
    nl: LegalForm.EBVBA,
    fr: LegalForm.SPRLU
  },
  [LegalFormSelectOptionKeys.VOF]: {
    nl: LegalForm.VOF,
    fr: LegalForm.SNC
  },
  [LegalFormSelectOptionKeys.Eenmanszaak]: {
    nl: LegalForm.INDIVIDUAL_NL,
    fr: LegalForm.INDIVIDUAL_FR
  },
  [LegalFormSelectOptionKeys.VME]: {
    nl: LegalForm.VME,
    fr: LegalForm.ACP
  }
}

/**
 * This is the new mapping of the legal form translations, where there is a combined nl/fr key for each legal form
 * We should use this one, also ask the backend to change it (if not too much work) to the new enum if you see the old one being used
 */
export const LEGAL_FORM_TRANSLATIONS: Record<SalesforceLegalForm, LegalFormTranslations> = {
  [SalesforceLegalForm.ACP_VME]: {
    nl: LegalForm.VME,
    fr: LegalForm.ACP
  },
  [SalesforceLegalForm.ASBL_VZW]: {
    nl: LegalForm.VZW,
    fr: LegalForm.ASBL
  },
  [SalesforceLegalForm.INDIVIDUELLE_EENMANSZAAK]: {
    nl: LegalForm.INDIVIDUAL_NL,
    fr: LegalForm.INDIVIDUAL_FR
  },
  [SalesforceLegalForm.SA_NV]: {
    nl: LegalForm.NV,
    fr: LegalForm.SA
  },
  [SalesforceLegalForm.SCRL_CVBA]: {
    nl: LegalForm.CVBA,
    fr: LegalForm.SCRL
  },
  [SalesforceLegalForm.SCS_GCV]: {
    nl: LegalForm.GCV,
    fr: LegalForm.SCS
  },
  [SalesforceLegalForm.SNC_VOF]: {
    nl: LegalForm.VOF,
    fr: LegalForm.SNC
  },
  [SalesforceLegalForm.SPRL_BVBA]: {
    nl: LegalForm.BVBA,
    fr: LegalForm.SPRL
  },
  [SalesforceLegalForm.SPRLU_EBVBA]: {
    nl: LegalForm.EBVBA,
    fr: LegalForm.SPRLU
  },
  [SalesforceLegalForm.SRL_BV]: {
    nl: LegalForm.BV,
    fr: LegalForm.SRL
  }
}

export const DATE_FORMAT = 'DD/MM/YYYY'

export const ALLOWED_IBAN_COUNTRIES = ['BE', 'FR', 'DE', 'LU', 'NL']
