import { SubnavProps as Props } from './types'
import classNames from 'classnames'
import styles from './Subnav.module.scss'
import parse from 'html-react-parser'
import { useState } from 'react'

const SubnavWithoutNavigation = ({ tabs, activeTab, className, noPushElement = false }: Props) => {
  const [focusedTab, setFocusedTab] = useState<number>(0)

  /**
   * Handles the tab click event
   *
   * @param {number} index
   * @returns
   */
  const handleTabClick = (index: number) => {
    setFocusedTab(index)
    activeTab(index)
  }

  return (
    <>
      <div className={classNames(styles.subnav, className)}>
        {tabs.map((tab, index) => (
          <button
            key={`subnav-${index}`}
            className={classNames(styles['subnav-wide-items'], styles['subnav-item'], {
              [styles.active]: tab.label === tabs[focusedTab].label
            })}
            onClick={() => handleTabClick(index)}
          >
            {typeof tab.label === 'string' ? parse(tab.label) : tab.label}
          </button>
        ))}
      </div>
      {!noPushElement && <div className={styles.push} />}
    </>
  )
}

export default SubnavWithoutNavigation
