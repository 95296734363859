import { Producer } from 'types/types'

/**
 * Calculates if the producer is sold out or not
 * @param {Producer} producer
 * @returns boolean
 */
export const isSoldOutWithCapacity = (producer: Producer): boolean => {
  // assume 3500 kWh
  const volume = 3500
  const used = Math.round(((producer.energyUsed + volume) / producer.maxCapacity) * 100)

  return used >= 100
}

/**
 * Function to add url params to cloudinary URL to scale image
 * @param {string} url
 * @param {number} [width]
 */
export const parseProducerImageUrl = (url?: string, width?: number) => {
  const widthToUse = width ? width : 800

  return url ? url.replace('/f_auto', `/w_${widthToUse},c_scale/f_auto`) : ''
}
