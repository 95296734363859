import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Dotdotdot from 'react-dotdotdot'
import classNames from 'classnames'
import { Store } from 'store/types'
import { ProducerEnergyType } from 'types/types'
import { parseProducerImageUrl } from 'utils/producer'
import { SelectProducerCardProps } from './types'
import styles from './SelectProducerCard.module.scss'
import { API_URL } from 'constants/envs'
import { Language } from 'store/app/types'
import ProducerLocation from 'components/ProducerLocation/ProducerLocation'
import { Heading } from '@boltenergy-be/design-system'
import Badge from 'components/Badge/Badge.tsx'
import { BadgeColors } from 'components/Badge/types.ts'

const SelectProducerCard = ({ producer, activeProducerId, handleSelectProducer, badge }: SelectProducerCardProps) => {
  // REDUX STORE
  const { language } = useSelector((store: Store) => store.app)

  // i18n
  const { t } = useTranslation()

  /**
   * Defines link to producer detail page on marketing website
   * @param {string} slug
   */
  const parseProducerReadMoreLink = (slug: string) => {
    const isStaging = API_URL.includes('staging') || API_URL.includes('localhost')
    const producersPath = language === Language.NL ? '/nl/onze-producenten' : '/fr/nos-producteurs'
    const baseUrl = isStaging ? 'https://staging.boltenergie.be' : 'https://www.boltenergie.be'

    return `${baseUrl}${producersPath}/${slug}`
  }

  return (
    <button
      className={classNames(styles.container, { [styles.active]: producer.id === activeProducerId })}
      onClick={() => handleSelectProducer(producer)}
    >
      {!!badge && <Badge color={BadgeColors.BLUE} className={styles.badge} text={badge} />}
      <img
        className={styles.image}
        src={parseProducerImageUrl(producer.images?.profile?.url, 600)}
        alt={`${producer.name[language]} thumbnail`}
      />
      <div className={styles.content}>
        <ProducerLocation
          energyType={producer.energyType}
          location={producer.location}
          energyName={t(`energyType.${producer.energyType || ProducerEnergyType.WIND}`, { ns: 'producer' })}
        />

        <div>
          <Heading as="h3" variant="h5" className="mb-200">
            <Dotdotdot clamp={1}>{producer.name[language]}</Dotdotdot>
          </Heading>
          <div className={styles.text}>
            {producer.description[language]}{' '}
            <a
              href={parseProducerReadMoreLink(producer.slug)}
              rel="noopener noreferrer"
              target="_blank"
              className={styles['read-more']}
              onClick={(e) => e.stopPropagation()}
            >
              {t('readMore')}
            </a>
          </div>
        </div>
      </div>
    </button>
  )
}

export default SelectProducerCard
