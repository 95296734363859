import { FC, useState } from 'react'
import FooterFaq from 'components/Faq/FooterFaq/FooterFaq'
import { useGetFaqBySlugQuery } from 'store/queries/cms-api'
import { Language } from 'store/app/types'
import { FaqSectionSlugs } from 'types/faq'
import { useSelector } from 'react-redux'
import { Store } from 'store/types'
import { useTranslation } from 'react-i18next'
import SeoTags from 'components/SeoTags/SeoTags'
import styles from './Friends.module.scss'
import Drawer from 'components/Drawer/Drawer'
import LinkAndShareCard from 'pages/App/Friends/components/LinkAndShareCard/LinkAndShareCard'
import parse from 'html-react-parser'
import { useGetCommunitySalesReferralsQuery, useGetReferralCodeQuery } from 'store/queries/bolt-api'
import { getBoltWebsiteOrigin } from 'utils/origins'
import classNames from 'classnames'
import LoadingDots from 'components/LoadingDots/LoadingDots'
import Card from 'components/Card/Card'
import { getActiveCommunitySalesActionPeriod } from 'utils/app'
import ActionCard from 'pages/App/Friends/components/ActionCard/ActionCard'
import dayjs from 'dayjs'
import { DEFAULT_REFERRAL_AMOUNT } from 'constants/referral'
import FriendStatus from 'pages/App/Friends/components/FriendStatus/FriendStatus.tsx'
import { Heading } from '@boltenergy-be/design-system'

const Friends: FC = () => {
  // REDUX
  const { language } = useSelector((store: Store) => store.app)
  const {
    info: { email }
  } = useSelector((store: Store) => store.user)

  // Dayjs
  dayjs.locale(language === Language.FR ? 'fr' : 'nl')

  // API
  const { data: referralsData, isLoading, isError } = useGetCommunitySalesReferralsQuery({ email })
  const { data: referralCodeData, isError: isReferralCodeError } = useGetReferralCodeQuery({ email })
  const { data: faq, isLoading: faqLoading } = useGetFaqBySlugQuery({
    language,
    slug: language === Language.FR ? FaqSectionSlugs.COMMUNITY_SALES_FR : FaqSectionSlugs.COMMUNITY_SALES_NL
  })

  // constants
  const friendsAmount = referralsData?.referrals.filter((referral) => !referral.pending)?.length ?? 0
  const singularOrPlural = friendsAmount === 1 ? 'singular' : 'plural'
  const activeAction = getActiveCommunitySalesActionPeriod()

  // Local state
  const [openDrawer, setOpenDrawer] = useState(false)

  // i18n
  const { t } = useTranslation('referral')

  // constants
  const amount = activeAction?.reduction || DEFAULT_REFERRAL_AMOUNT
  const referralLink = `${getBoltWebsiteOrigin()}${
    language === Language.FR ? 'fr/convaincre-un-ami' : 'nl/overtuig-een-vriend'
  }?referralcode=${referralCodeData?.code ?? ''}`
  const rootKey = activeAction ? 'activeAction' : 'default'
  const seoTitle = t(`actions.${rootKey}.seo.title`, { amount })
  const seoDescription = t(`actions.${rootKey}.seo.description`, {
    amount,
    endDate: activeAction ? dayjs(activeAction.end).format('D MMMM') : ''
  })

  return (
    <>
      <div className={styles.root}>
        <SeoTags title={seoTitle}>
          <meta property="og:image" content={`${window.location.origin}/images/referral/${amount}-${language}.jpg`} />
          <meta property="og:title" content={seoTitle} />
          <meta name="description" content={seoDescription} />
          <meta property="og:description" content={seoDescription} />
        </SeoTags>

        <div className="content">
          <div className={classNames('container', styles.container)}>
            <article className={styles['card-group']}>
              {/* INTRO */}
              <Card as="section" className={classNames(styles['header-card'])}>
                <Card.Title as="h1" variant="h2">
                  {t('intro.title')}
                </Card.Title>
                <p>{t('intro.description')}</p>
              </Card>

              {/* HOW IT WORKS */}
              <Card as="section" className={styles['steps-card']}>
                <Heading as="h2" variant="h4">
                  {t('howItWorks.heading')}
                </Heading>

                <ol className={styles.steps}>
                  <li className={styles.step}>
                    <span className={styles['step-number']}>1</span>
                    <div className={styles['step-body']}>
                      <Heading as="h3" variant="h6">
                        {t('howItWorks.steps.one.heading')}
                      </Heading>
                      <p>{t('howItWorks.steps.one.paragraph')}</p>
                    </div>
                  </li>

                  <li className={styles.step}>
                    <span className={styles['step-number']}>2</span>
                    <div className={styles['step-body']}>
                      <Heading as="h3" variant="h6">
                        {t('howItWorks.steps.two.heading')}
                      </Heading>
                      <p>{t('howItWorks.steps.two.paragraph')}</p>
                    </div>
                  </li>

                  <li className={styles.step}>
                    <span className={styles['step-number']}>3</span>
                    <div className={styles['step-body']}>
                      <Heading as="h3" variant="h6">
                        {t('howItWorks.steps.three.heading', { amount })}
                      </Heading>
                      <p>{t('howItWorks.steps.three.paragraph', { amount })}</p>
                    </div>
                  </li>
                </ol>
              </Card>

              {/* SHARE */}
              <LinkAndShareCard amount={amount} shareLink={referralLink} hasError={isReferralCodeError} />
            </article>

            {/* ACTIVE ACTION CARD */}
            {!!activeAction && <ActionCard openDrawer={() => setOpenDrawer(true)} {...{ activeAction }} />}

            {/* REFERRALS */}
            <article className={styles['card-group']}>
              <Card className={styles['friends-card']}>
                {isLoading ? (
                  <LoadingDots />
                ) : isError || !friendsAmount ? (
                  <div className={styles['no-friends']}>
                    <Card.Title>{t('referrals.noFriends.heading')}</Card.Title>
                    <p>{t('referrals.noFriends.paragraph')}</p>
                  </div>
                ) : (
                  <>
                    <div className={styles['friends-header']}>
                      <Card.Title>{t(`referrals.hasFriends.${singularOrPlural}.heading`, { friendsAmount })}</Card.Title>
                      <p>{t(`referrals.hasFriends.${singularOrPlural}.paragraph`, { friendsAmount })}</p>
                    </div>
                    <hr />
                    <ul className={styles['friends-content']}>
                      {/* Pending referrals first */}
                      {[...(referralsData?.referrals ?? [])]
                        .sort((a, b) => (a.pending === b.pending ? 0 : a.pending ? -1 : 1))
                        .map((referral, index) => (
                          <FriendStatus key={index} referral={referral} />
                        ))}
                    </ul>
                  </>
                )}
              </Card>
            </article>
          </div>

          {/* Community Sales FAQ */}
          <FooterFaq isLoading={faqLoading} category={faq?.category} subtitle={t('faq.subtitle')} />
        </div>
      </div>

      {!!activeAction && (
        <Drawer
          isOpen={openDrawer}
          onRequestClose={() => setOpenDrawer(false)}
          title={t('actions.activeAction.drawer.heading')}
          contentClassName={styles['drawer-content']}
        >
          {parse(
            t('actions.activeAction.drawer.textContent', {
              endDate: dayjs(activeAction.end).format('D MMMM'),
              fullEndDate: dayjs(activeAction.end).format('D MMMM YYYY'),
              fullStartDate: dayjs(activeAction.start).format('D MMMM'),
              reduction: activeAction.reduction
            })
          )}
        </Drawer>
      )}
    </>
  )
}

export default Friends
