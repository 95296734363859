import { useState, PropsWithChildren } from 'react'
import styles from 'features/contracts/add/components/ProducerSliderLayout/ProducerSliderLayout.module.scss'
import { ProducerSliderLayoutProps } from 'features/contracts/add/components/ProducerSliderLayout/types'
import Drawer from 'components/Drawer/Drawer'
import classNames from 'classnames'
import { Electricity, Gas } from 'assets/svg'
import { useTranslation } from 'react-i18next'
import { useStoreSelector } from 'hooks/store'
import { Language } from 'store/app/types'
import { AddContractSteps, SimulationType } from 'features/contracts/add/types'
import { getProductFromProductCode } from 'utils/products'
import { useGetProductContentQuery } from 'store/queries/cms-api'
import PriceDisplay from 'features/contracts/add/components/PriceDisplay/PriceDisplay'
import Icon from 'components/Icon/Icon'
import { Product } from 'types/products'

const ProducerSliderLayout = ({ children, contractData, currentStep }: PropsWithChildren<ProducerSliderLayoutProps>) => {
  // Redux store
  const { language } = useStoreSelector((store) => store.app)

  // Redux queries
  const { data: productContent } = useGetProductContentQuery({ language })

  // i18n
  const { t } = useTranslation('producer')

  // Local state
  const [showMobileDrawer, setShowMobileDrawer] = useState<boolean>(false)

  // Constants
  const producer = contractData[AddContractSteps.PRODUCER]
  const { needsGas } = contractData[AddContractSteps.ADDRESS]
  const { simulationType, instalments } = contractData[AddContractSteps.INSTALMENT_DATA]
  const totalInstalment = (instalments.electricity || 0) + (needsGas ? instalments.gas || 0 : 0)
  const product = getProductFromProductCode(contractData[AddContractSteps.PRODUCT].electricity)
  const productName =
    [
      AddContractSteps.INSTALMENT_DATA,
      AddContractSteps.METER_DETAILS,
      AddContractSteps.CUSTOMER_DATA,
      AddContractSteps.CONFIRMATION
    ].includes(currentStep) && product
      ? `Bolt ${productContent?.[product]?.name}`
      : undefined
  const showPrice = [AddContractSteps.METER_DETAILS, AddContractSteps.CUSTOMER_DATA, AddContractSteps.CONFIRMATION].includes(currentStep)

  return (
    <>
      <button className={classNames(styles.card, { [styles['has-price']]: showPrice })} onClick={() => setShowMobileDrawer(true)}>
        <div className={styles['producer-container']}>
          <picture>
            <img src={producer?.images.profile?.url} alt="" />
            <div className={styles['energy-types']}>
              {needsGas && (
                <span className={styles.gas}>
                  <Gas isFilled />
                </span>
              )}
              <span className={styles.electricity}>
                <Electricity isFilled />
              </span>
            </div>
          </picture>

          <div className={styles.producer}>
            <small>
              {t('energyTypeAndLocation', '{{ energyType }} uit {{ location }} van', {
                energyType:
                  language === Language.FR
                    ? t(`energyType.${producer?.energyType || 'Solar'}`).toLowerCase()
                    : t(`energyType.${producer?.energyType || 'Solar'}`),
                location: producer?.location
              })}
            </small>
            <h3>{producer?.name?.[language]}</h3>
          </div>

          <span className={styles['open-icon']}>
            <Icon name="arrowUp" />
          </span>
        </div>

        {productName && (
          <>
            <hr />
            <div className={styles['price-container']}>
              <span className={styles.product}>{productName}</span>

              {showPrice && productName && simulationType !== SimulationType.EAV && (
                <PriceDisplay amount={totalInstalment} label={product === Product.GO ? t('perYear', { ns: 'common' }) : undefined} />
              )}
            </div>
          </>
        )}
      </button>

      <Drawer
        headerImage={
          producer
            ? { alt: t('alt', 'Foto van {{ producer }}', { producer: producer!.name?.[language] }), url: producer!.images?.profile?.url }
            : undefined
        }
        contentClassName={styles.drawer}
        isOpen={showMobileDrawer}
        onRequestClose={() => setShowMobileDrawer(false)}
      >
        {children}
      </Drawer>
    </>
  )
}

export default ProducerSliderLayout
