import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import mixpanel from 'mixpanel-browser'
import { ContractType, ProductType } from 'types/types.ts'
import dayjs from 'dayjs'
import { DATE_FORMAT } from 'constants/constants.ts'
import styles from 'pages/App/Contract/ContractTab/ContractTab.module.scss'
import Icon from 'components/Icon/Icon.tsx'
import classNames from 'classnames'
import { Button } from '@boltenergy-be/design-system'
import { routes } from 'types/routes.ts'
import parse from 'html-react-parser'
import { getMemoizedSelectedContracts, isInactiveContract } from 'utils/contracts.ts'
import { MX_CONTRACT_FLOW } from 'constants/trackingIds.ts'
import { Language } from 'store/app/types.ts'
import { useGetProductContentQuery } from 'store/queries/cms-api'
import { getProductFromProductCode } from 'utils/products.ts'
import Card from 'components/Card/Card.tsx'
import { Heading, Banner } from '@boltenergy-be/design-system'
import LoadingSkeleton from 'components/LoadingSkeleton/LoadingSkeleton.tsx'
import Charts from 'assets/svg/Charts.tsx'
import Modal from 'components/Modal/Modal.tsx'
import { ContractFlowTrackingTypes, SmartMeterOptionEvents, SmartMeterOptionTrackingParams } from 'types/tracking.ts'
import SmartMeterOption from 'pages/App/Contract/ContractTab/components/SmartMeterOption/SmartMeterOption.tsx'
import { SmartMeterOptions } from 'pages/App/Contract/ContractTab/types.ts'
import { AddHouse, Calendar, CloseHouse } from 'assets/svg'
import { patchServiceContract } from 'api/contracts.ts'
import { log } from 'utils/logging.ts'
import { Flow } from 'types/logging.ts'
import Bugsnag from '@bugsnag/js'
import {
  checkHasSmartMeterOptionsForContract,
  checkIsDynamicTariffEligible,
  checkIsMonthlyBilling,
  checkIsMonthlyBillingEligible
} from 'utils/smartBillingOptions.ts'
import { WEBSITE_URL } from 'constants/envs.ts'
import { MoveFlows } from 'store/user/types.ts'
import { useStoreDispatch, useStoreSelector } from 'hooks/store.ts'
import { setMoveFlow } from 'store/user/slice.ts'
import Link from 'components/Link/Link.tsx'
import OutlinedLinkButton from 'pages/App/Contract/ContractTab/components/OutlinedLinkButton/OutlinedLinkButton.tsx'

const ContractTab = () => {
  // REDUX STORE
  const { language } = useStoreSelector((store) => store.app)
  const { customers, selectedContracts, selectedCustomer } = useStoreSelector((store) => store.user)
  const dispatch = useStoreDispatch()

  // Redux queries
  const { data: productsContent, isLoading: loadingProductsContent } = useGetProductContentQuery({ language })

  // i18n
  const { t } = useTranslation('contract')

  // Local state
  const [confirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false)
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false)
  const [isErrored, setIsErrored] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [extraOptionsEditable, setExtraOptionsEditable] = useState<boolean>(false)

  // Constants
  const currentCustomer = customers[selectedCustomer]
  const { electricityContract, gasContract } = getMemoizedSelectedContracts(selectedContracts)
  const inactiveContract = isInactiveContract(electricityContract)
  const electricityDate = electricityContract ? dayjs(electricityContract.detail.contractualStartDate).format(DATE_FORMAT) : null
  const gasDate = gasContract ? dayjs(gasContract.detail.contractualStartDate).format(DATE_FORMAT) : null
  const elProduct = electricityContract ? getProductFromProductCode(electricityContract.detail.productCode) : undefined
  const gasProduct = gasContract ? getProductFromProductCode(gasContract.detail.productCode) : undefined
  const hasExtraSmartMeterOptions = checkHasSmartMeterOptionsForContract(electricityContract)
  const isDynamicTariffEligible = checkIsDynamicTariffEligible({ electricityContract, product: elProduct })
  const isMonthlyBillingEligible = checkIsMonthlyBillingEligible({ electricityContract, gasContract, product: elProduct })
  const isDynamicTariff = electricityContract.dynamicTariff
  const isMonthlyBilling = checkIsMonthlyBilling(isMonthlyBillingEligible, electricityContract, gasContract)

  // Local form state
  const [options, setOptions] = useState<{ monthly: boolean; dynamic: boolean }>({
    dynamic: !!isDynamicTariff,
    monthly: !!isMonthlyBilling
  })

  /**
   * Activates or deactivates the monthly billing & dynamic tariff
   */
  const updateSmartMeterOptions = async () => {
    setIsLoading(true)

    // Track event in mixpanel
    mixpanel.track(SmartMeterOptionEvents.EDIT_OPTIONS_CONFIRMED, {
      [SmartMeterOptions.MONTHLY_BILLING]: options.monthly,
      [SmartMeterOptions.DYNAMIC_TARIFF]: options.dynamic
    })

    try {
      const contractsToUpdate = [electricityContract]

      // Only update gas contract if it exists and is eligible for monthly billing (gas contracts can't have dynamic)
      if (gasContract && isMonthlyBillingEligible.gas) {
        contractsToUpdate.push(gasContract)
      }

      Bugsnag.addMetadata('contractsToUpdate', contractsToUpdate)

      const res = await Promise.all(
        contractsToUpdate.map((contract) => {
          // Define body
          const body =
            contract.type === ContractType.ELECTRICITY
              ? {
                  monthlyBilling: options.monthly,
                  dynamicTariff: options.dynamic
                }
              : {
                  monthlyBilling: options.monthly
                }

          // Add body to the meta data (for logging if error)
          Bugsnag.addMetadata('requestData', body)

          // Return the patch request promise
          return patchServiceContract(contract.id, body)
        })
      )

      if (res.every(({ success }) => success)) {
        setIsConfirmed(true)
      } else {
        setIsErrored(true)
        resetOptions()

        const msg = res.find(({ message }) => message)?.message

        log({
          error: 'ApiError',
          metaData: {
            data: {
              message: msg
            }
          },
          identifier: `[${Flow.CONTRACT}:patchServiceContract]`
        })
      }
    } catch (error) {
      setIsErrored(true)
      resetOptions()

      log({ error: error as Error, identifier: `[${Flow.CONTRACT}:patchServiceContract]` })
    }

    setConfirmationModalOpen(false)
    setExtraOptionsEditable(false)
    setIsLoading(false)
  }

  /**
   * Resets the options for the contracts
   */
  const resetOptions = () => {
    setOptions({
      dynamic: !!isDynamicTariff,
      monthly: !!isMonthlyBilling
    })
  }

  /**
   * Returns the delivery address for the given product type
   *
   * @param {ProductType} type
   * @returns {string}
   */
  const getProductAddress = (type: ProductType): string => {
    const product = type === ProductType.ELECTRICITY ? electricityContract : gasContract

    return `
      ${product?.address.streetName} ${product?.address.streetNumber} ${product?.address.streetBox}
      ${product?.address.postalCode} ${product?.address.townName}
    `
  }

  /**
   * Checks the difference between the current options and the original options
   * @returns {{dynamic: boolean, monthly: boolean}}
   */
  const checkOptionsDifferenceWithOriginal = () => {
    return {
      monthly: isMonthlyBilling !== options.monthly,
      dynamic: isDynamicTariff !== options.dynamic
    }
  }

  return (
    <>
      {/* MY CONNECTIONS BLOCK */}
      <Card className={classNames('container', styles['my-connections'])}>
        <Heading as="h1" variant="h2">
          {t('myContract.title')}
        </Heading>

        <section className={styles.products}>
          <header>
            <small>{t('myContract.currentPlan')}</small>
            {!elProduct || loadingProductsContent ? (
              <LoadingSkeleton className={styles['product-loader']}>
                <LoadingSkeleton.Rectangle width={240} height={20} />
              </LoadingSkeleton>
            ) : (
              <Card.Title className={styles.product}>Bolt {productsContent?.[elProduct]?.name}</Card.Title>
            )}
          </header>

          {electricityContract && elProduct && (
            <Card className={styles.card}>
              <div className={styles.type}>
                <Icon name="flash" />
                <Card.Title>{t('electricity', { ns: 'common' })}</Card.Title>
              </div>

              <table className={styles['product-table']}>
                <tbody>
                  {/* STATUS */}
                  <tr>
                    <th>{t('myContract.status')}</th>
                    <td>{t(`myContract.contractStatus.${electricityContract.detail.status}`)}</td>
                  </tr>

                  {/* ADDRESS */}
                  <tr>
                    <th>{t('myContract.address')}</th>
                    <td>{getProductAddress(ProductType.ELECTRICITY)}</td>
                  </tr>

                  {/* EAN */}
                  <tr>
                    <th>{t('myContract.ean')}</th>
                    <td>{electricityContract.ean}</td>
                  </tr>

                  {/* START DATE */}
                  <tr>
                    <th>{t('myContract.startDate')}</th>
                    <td>{electricityDate}</td>
                  </tr>

                  {/* TARIFF CARD */}
                  <tr>
                    <th>{t('myContract.tariffCard')}</th>
                    <td className={styles.downloadpdf}>
                      <a href={`https://${electricityContract.detail.priceList}`} download={true} target="_blank" rel="noopener noreferrer">
                        {t('myContract.downloadPDF')}
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Card>
          )}

          <Card className={classNames(styles.card, styles.gas)}>
            <div className={styles.type}>
              <Icon name="gas" />
              <Card.Title>{t('gas', { ns: 'common' })}</Card.Title>
            </div>

            {gasContract && gasProduct ? (
              <table className={styles['product-table']}>
                <tbody>
                  {/* STATUS */}
                  <tr>
                    <th>{t('myContract.status')}</th>
                    <td>{t(`myContract.contractStatus.${gasContract.detail.status}`)}</td>
                  </tr>

                  {/* ADDRESS */}
                  <tr>
                    <th>{t('myContract.address')}</th>
                    <td>{getProductAddress(ProductType.GAS)}</td>
                  </tr>

                  {/* EAN */}
                  <tr>
                    <th>{t('myContract.ean')}</th>
                    <td>{gasContract.ean}</td>
                  </tr>

                  {/* START DATE */}
                  <tr>
                    <th>{t('myContract.startDate')}</th>
                    <td>{gasDate}</td>
                  </tr>

                  {/* TARIFF CARD */}
                  <tr>
                    <th>{t('myContract.tariffCard')}</th>
                    <td className={styles.downloadpdf}>
                      <a href={`https://${gasContract.detail.priceList}`} download={true} target="_blank" rel="noopener noreferrer">
                        {t('myContract.downloadPDF')}
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <div className={styles.cta}>
                <Heading as="h3" variant="h6" weight={600} className="mb-100">
                  {t('myContract.noGasYet.title')}
                </Heading>
                <p>{parse(t('myContract.noGasYet.description'))}</p>
              </div>
            )}
          </Card>

          <div className={styles.actions}>
            <OutlinedLinkButton
              className={styles.terminate}
              onClick={() => {
                const flow = MoveFlows.START
                mixpanel.track(MX_CONTRACT_FLOW.start, { flow, type: ContractFlowTrackingTypes.ADD })
                dispatch(setMoveFlow(flow))
              }}
              href={routes.CONTRACTS_ADD}
              representation="button"
            >
              <AddHouse />
              {t('myContract.addContract')}
            </OutlinedLinkButton>

            {!inactiveContract && (
              <OutlinedLinkButton
                className={styles.terminate}
                onClick={() => {
                  const flow = MoveFlows.STOP
                  mixpanel.track(MX_CONTRACT_FLOW.start, { flow, type: ContractFlowTrackingTypes.TERMINATE })
                  dispatch(setMoveFlow(flow))
                }}
                representation="button"
                href={routes.CONTRACTS_TERMINATE}
              >
                <CloseHouse />
                {t('myContract.terminateContract')}
              </OutlinedLinkButton>
            )}
          </div>
        </section>

        {/* SOCIAL TARIFF */}
        {!!currentCustomer?.attestations?.length &&
          currentCustomer.attestations.some((attestation) => attestation.ean === electricityContract.ean) && (
            <section className={classNames('container', styles['social-tariff'])}>
              <div className={styles.text}>
                <Heading as="h2" variant="h6" className="mb-200">
                  {t('myContract.socialTarif.title')}
                </Heading>
                <p>{t('myContract.socialTarif.description')}:</p>
                <ul>
                  {currentCustomer.attestations.map((attestation, index) => (
                    <li key={index}>
                      {dayjs(attestation.fromDate).format('DD/MM/YYYY')} {t('myContract.socialTarif.till')}{' '}
                      {dayjs(attestation.toDate).format('DD/MM/YYYY')}
                    </li>
                  ))}
                </ul>
              </div>

              <Link
                href={
                  language === Language.NL
                    ? 'https://economie.fgov.be/nl/themas/energie/energieprijzen/sociaal-tarief-voor-energie'
                    : 'https://economie.fgov.be/fr/themes/energie/prix-de-lenergie/tarif-social-pour-lenergie'
                }
                external
                className={styles['more-info']}
              >
                {t('myContract.socialTarif.moreInfo')}
              </Link>
            </section>
          )}

        {hasExtraSmartMeterOptions && (
          <section className={styles['smart-meter-options']}>
            <Card.Title>{t('myContract.smartMeterOptions.title')}</Card.Title>
            <ul>
              {/* DYNAMIC TARIFF */}
              {isDynamicTariffEligible && (
                <SmartMeterOption
                  title={t('myContract.smartMeterOptions.dynamicTariff.title')}
                  option={SmartMeterOptions.DYNAMIC_TARIFF}
                  icon={<Charts />}
                  isActive={options.dynamic}
                  readMoreUrl={`${WEBSITE_URL}/${language}/dynamic-prices`}
                  onChange={(state) => {
                    mixpanel.track(SmartMeterOptionEvents.EDIT_OPTION_TOGGLED, {
                      [SmartMeterOptionTrackingParams.OPTION]: SmartMeterOptions.DYNAMIC_TARIFF,
                      [SmartMeterOptionTrackingParams.NEW_VALUE]: options.dynamic
                    })

                    setOptions({ ...options, dynamic: state })
                  }}
                  editable={extraOptionsEditable}
                />
              )}

              {/* MONTHLY BILLING */}
              {isMonthlyBillingEligible.electricity && (
                <SmartMeterOption
                  title={t(`myContract.smartMeterOptions.monthlyBilling.title.${isMonthlyBillingEligible.gas ? 'elAndGas' : 'elOnly'}`)}
                  option={SmartMeterOptions.MONTHLY_BILLING}
                  icon={<Calendar />}
                  isActive={options.monthly}
                  editable={extraOptionsEditable}
                  onChange={(state) => {
                    mixpanel.track(SmartMeterOptionEvents.EDIT_OPTION_TOGGLED, {
                      [SmartMeterOptionTrackingParams.OPTION]: SmartMeterOptions.MONTHLY_BILLING,
                      [SmartMeterOptionTrackingParams.NEW_VALUE]: options.monthly
                    })

                    setOptions({ ...options, monthly: state })
                  }}
                />
              )}
            </ul>

            <Button
              onClick={() => {
                if (extraOptionsEditable) {
                  const differences = checkOptionsDifferenceWithOriginal()
                  const sameAsOriginal = Object.values(differences).every((value) => !value)

                  if (sameAsOriginal) {
                    mixpanel.track(SmartMeterOptionEvents.EDIT_OPTIONS_CANCELED, {
                      [SmartMeterOptions.MONTHLY_BILLING]: options.monthly,
                      [SmartMeterOptions.DYNAMIC_TARIFF]: options.dynamic,
                      [SmartMeterOptionTrackingParams.SAME_AS_ORIGINAL]: sameAsOriginal
                    })

                    setExtraOptionsEditable(false)
                  } else {
                    mixpanel.track(SmartMeterOptionEvents.EDIT_OPTIONS_CONFIRMATION_OPENED, {
                      [SmartMeterOptions.MONTHLY_BILLING]: options.monthly,
                      [SmartMeterOptions.DYNAMIC_TARIFF]: options.dynamic
                    })

                    setConfirmationModalOpen(true)
                  }
                } else {
                  mixpanel.track(SmartMeterOptionEvents.EDIT_OPTIONS_STARTED, {
                    [SmartMeterOptions.MONTHLY_BILLING]: options.monthly,
                    [SmartMeterOptions.DYNAMIC_TARIFF]: options.dynamic
                  })

                  setExtraOptionsEditable(true)
                }
              }}
            >
              {t(`myContract.smartMeterOptions.actions.${extraOptionsEditable ? 'save' : 'edit'}`)}
            </Button>

            {(isConfirmed || isErrored) && (
              <Banner
                type={isErrored ? 'blocking' : 'positive'}
                title={isErrored ? t('myContract.smartMeterOptions.error.title') : t('myContract.smartMeterOptions.confirmation.title')}
              >
                <p>
                  {parse(
                    isErrored
                      ? t('myContract.smartMeterOptions.error.description')
                      : t('myContract.smartMeterOptions.confirmation.description')
                  )}
                </p>
              </Banner>
            )}
          </section>
        )}
      </Card>

      {/* CONFIRMATION MODAL */}
      <Modal isOpen={confirmationModalOpen} setClose={() => setConfirmationModalOpen(false)}>
        <Heading as="h2" variant="h4">
          {t('myContract.modal.update.title')}
        </Heading>

        {isDynamicTariffEligible && checkOptionsDifferenceWithOriginal().dynamic && (
          <div>
            <Heading as="h3" variant="h6" className="mb-200">
              {t(`myContract.modal.update.dynamicTariff.${options.dynamic ? 'activate' : 'deactivate'}.title`)}
            </Heading>
            <p>{parse(t(`myContract.modal.update.dynamicTariff.${options.dynamic ? 'activate' : 'deactivate'}.description`))}</p>
          </div>
        )}

        {isMonthlyBillingEligible && checkOptionsDifferenceWithOriginal().monthly && (
          <div>
            <Heading as="h3" variant="h6" className="mb-200">
              {t(`myContract.modal.update.monthlyBilling.${options.monthly ? 'activate' : 'deactivate'}.title`)}
            </Heading>
            <p>{parse(t(`myContract.modal.update.monthlyBilling.${options.monthly ? 'activate' : 'deactivate'}.description`))}</p>
          </div>
        )}

        <p>{parse(t('myContract.modal.update.subtext'))}</p>

        <div className={styles['actions-group']}>
          <Button loading={isLoading} onClick={updateSmartMeterOptions}>
            {t('myContract.modal.confirm')}
          </Button>

          <Button
            variant="secondary"
            disabled={isLoading}
            onClick={() => {
              mixpanel.track(SmartMeterOptionEvents.EDIT_OPTIONS_CANCELED, {
                [SmartMeterOptions.MONTHLY_BILLING]: options.monthly,
                [SmartMeterOptions.DYNAMIC_TARIFF]: options.dynamic
              })

              setConfirmationModalOpen(false)
            }}
          >
            {t('myContract.modal.cancel')}
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default ContractTab
