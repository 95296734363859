import { NoInvoicesProps, UsedRoutes } from 'pages/App/Billing/Invoices/NoInvoices/types.ts'
import { routes } from 'types/routes.ts'
import styles from './NoInvoices.module.scss'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const NoInvoices = ({ route }: NoInvoicesProps) => {
  // i18N
  const { t } = useTranslation('invoices')

  // constants
  const withImage = [routes.BILLING_ALL_TRANSACTIONS, routes.BILLING_ALL_INVOICES, routes.BILLING_OUTSTANDING_INVOICES].includes(route)

  return (
    <div className={styles.root}>
      {withImage && <img className={styles.image} src="/images/billing/no-invoices.png" alt="" />}
      <div className={styles['text-content']}>
        <h2 className={styles.title}>{t(`noInvoices.${route as UsedRoutes}.title`)}</h2>
        {route === routes.BILLING_OUTSTANDING_INVOICES && (
          <Link className={styles.link} to={routes.BILLING_ALL_INVOICES}>
            {t(`noInvoices.${route as routes.BILLING_OUTSTANDING_INVOICES}.linkText`)}
          </Link>
        )}
      </div>
    </div>
  )
}

export default NoInvoices
