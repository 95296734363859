import { PropsWithChildren } from 'react'
import styles from 'features/contracts/add/components/ProducerCardLayout/ProducerCardLayout.module.scss'
import { ProducerCardLayoutProps } from 'features/contracts/add/components/ProducerCardLayout/types'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import ProducerLocation from 'components/ProducerLocation/ProducerLocation'

const ProducerCardLayout = ({
  producer,
  hideProducerImage,
  hideProducerLocation,
  className,
  headerClass,
  children,
  loading
}: PropsWithChildren<ProducerCardLayoutProps>) => {
  // i18n
  const { t } = useTranslation('producer')

  return !loading && producer ? (
    <div className={styles.card}>
      {/* CARD HEADER */}
      {!hideProducerImage && (
        <figure className={classNames(styles['card-header'], headerClass)}>
          <img src={producer.images.profile?.url} alt={t('alt', 'Foto van {{ producerName }}', { producerName: producer.name.nl })} />
          {!hideProducerLocation && <ProducerLocation {...producer} className={styles.location} />}
        </figure>
      )}

      {/* CARD CONTENT */}
      <div className={classNames(styles['card-content-container'], { [styles['fully-boxed']]: hideProducerImage }, className)}>
        {children}
      </div>
    </div>
  ) : loading ? (
    <div className={classNames(styles.card, styles.skeleton)}>
      <div className={styles['card-header']} />

      <div className={styles['card-content-container']}>
        <div className={styles.loader} />
        <div className={styles.loader} />
        <div className={styles.loader} />
      </div>
    </div>
  ) : (
    <></>
  )
}

export default ProducerCardLayout
