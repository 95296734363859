import { store } from 'store'
import { routes } from 'types/routes'
import { saveRedirect, saveUrlSearchParam, activateBetaMode } from 'store/app/slice'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { UrlSearchParamsKeys } from 'store/app/types'

/**
 * Executes start up functions
 */
export const startUp = (): void => {
  // Initialize tools & store subscription
  initializeBugsnag()
  // initializeLogRocket() // Disabled for now

  // Save url search params if necessary
  saveUrlSearchParamsOnStartup()

  // Save redirect if necessary
  saveRequestedRouteAsRedirectOnStartup()
}

/**
 * Initializes the Bugsnag plugin
 */
const initializeBugsnag = () => {
  Bugsnag.start({
    apiKey: '881ae461383c80cc1a4da51a5060e431',
    plugins: [new BugsnagPluginReact()],
    enabledReleaseStages: ['production']
  })
}

/**
 * Initializes the LogRocket plugin
 */
// const initializeLogRocket = () => {
//   LogRocket.init('qkcmis/bolt', {
//     network: {
//       requestSanitizer: (request) => {
//         // if the url contains 'login'
//         if (request.body && request.url.toLowerCase().indexOf('login') !== -1) {
//           // scrub out the body
//           const body = JSON.parse(request.body)
//           body.password = 'NOT SHOWN'
//           request.body = JSON.stringify(body)
//         }

//         // scrub token from request
//         if (request.headers['Authorization']) {
//           request.headers['Authorization'] = ''
//         }

//         // make sure you return the modified request
//         return request
//       }
//     }
//   })
// }

/**
 * Saves the requested route as redirect in store on startup
 */
const saveRequestedRouteAsRedirectOnStartup = (): void => {
  const { pathname, search } = window.location

  const betaMode = new URLSearchParams(window.location.search).get('beta') === 'true'

  if (betaMode) {
    store.dispatch(activateBetaMode())
  }

  // Only save protected route paths as a redirect that are not /home
  if (
    ![
      '/',
      routes.WELCOME,
      routes.LOGIN_SALES,
      routes.SALES_HOME,
      routes.LOGIN,
      routes.SUPER_USER_LOGIN,
      routes.CUSTOMER_EMAIL,
      routes.REGISTER,
      routes.REQUEST_NEW_PASSWORD
    ].includes(pathname) &&
    !pathname.startsWith(routes.ACTIVATE) &&
    !pathname.startsWith(routes.RESET_PASSWORD)
  ) {
    let redirectUrl = `${pathname}${search}`

    // If redirectUrl is deprecated energy overview route, update to new route
    if (redirectUrl === routes.DEPRECATED_ENERGY_OVERVIEW) {
      redirectUrl = routes.CONSUMPTION_ENERGY_OVERVIEW
    }

    store.dispatch(saveRedirect(redirectUrl))
  }
}

/**
 * Checks the URL search params & saves them to the store if necessary
 */
const saveUrlSearchParamsOnStartup = () => {
  // Fetch the URL params
  const search = new URLSearchParams(window.location.search)

  if (search.has(UrlSearchParamsKeys.CONTACT_ID))
    store.dispatch(saveUrlSearchParam({ key: UrlSearchParamsKeys.CONTACT_ID, value: search.get(UrlSearchParamsKeys.CONTACT_ID) ?? '' }))

  if (search.has(UrlSearchParamsKeys.CUSTOMER_EMAIL)) {
    store.dispatch(
      saveUrlSearchParam({ key: UrlSearchParamsKeys.CUSTOMER_EMAIL, value: search.get(UrlSearchParamsKeys.CUSTOMER_EMAIL) ?? '' })
    )
  }
}
