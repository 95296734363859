import { Button, Icon } from '@boltenergy-be/design-system'
import { EditingButtonProps } from './types'
import styles from './EditingButton.module.scss'
import { useTranslation } from 'react-i18next'
import TooltipOnHover from 'components/Tooltip/TooltipOnHover'

const EditingButton = ({ isDisabled, isEditing, setIsEditing, isLoading, onSubmit, identifier }: EditingButtonProps) => {
  //i18n
  const { t } = useTranslation()

  return (
    <>
      {!isEditing ? (
        <Button
          type="button"
          variant="tertiary"
          disabled={isDisabled}
          onClick={() => setIsEditing(identifier)}
          className={styles['pencil-button']}
        >
          <TooltipOnHover
            tooltipContent={t('details.tooltips.edit', { ns: 'sales' })}
            disabled={isDisabled}
            tooltipClassname={styles['tooltip-hover']}
          >
            <Icon name="pencil" />
          </TooltipOnHover>
        </Button>
      ) : (
        <div className={styles['finish-editing']}>
          <Button variant="secondary" type="button" onClick={() => setIsEditing(undefined)} disabled={isLoading}>
            {t('cancel')}
          </Button>
          <Button
            type="button"
            loading={isLoading}
            onClick={() => {
              onSubmit && onSubmit()
            }}
          >
            {t('save')}
          </Button>
        </div>
      )}
    </>
  )
}

export default EditingButton
