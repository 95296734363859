import { ReactElement } from 'react'
import { TableHeaderValuesType, TableHeaderValueType } from 'pages/App/Billing/Invoices/InvoicesTable/types.ts'

export enum SortingDirection {
  ASC = 'asc',
  DESC = 'desc'
}

type OnSortProps = {
  id: string
  direction: SortingDirection
}

export type TableProps = {
  headers: TableHeaderValueType[] | TableHeaderValuesType[][]
  rows: Row[]
  className?: string
  onSort?: ({ id, direction }: OnSortProps) => void
  sorting?: OnSortProps
}

export type TableHeaderProps = {
  header: TableHeaderValueType | TableHeaderValuesType[]
} & Pick<TableProps, 'onSort' | 'sorting'>

export type Row = {
  cells: Cell[]
}

export type RawDataType = string | number | boolean | { [key: string]: string }

export type Cell = {
  value: string | ReactElement
  rawData?: RawDataType
  id?: string
  onClick?: () => void
  extraClasses?: string
}
