import TooltipOnClick from 'components/Tooltip/TooltipOnClick.tsx'
import Icon from 'components/Icon/Icon.tsx'
import styles from './Options.module.scss'
import { CustomerInvoice } from 'types/types.ts'
import { OptionsProps, ToolTipContentProps } from 'pages/App/Billing/Invoices/InvoicesTable/Options/types.ts'
import { CellVariant } from 'pages/App/Billing/Invoices/InvoicesTable/Cell/types.ts'
import Cell from 'pages/App/Billing/Invoices/InvoicesTable/Cell/Cell.tsx'
import { useTranslation } from 'react-i18next'
import { getDocumentData } from 'pages/App/Billing/Invoices/hooks/utils.tsx'
import Link from 'components/Link/Link.tsx'

const ToolTipContent = ({ document }: ToolTipContentProps) =>
  !!(document as CustomerInvoice)?.pdfUrl && (
    <Link
      href={(document as CustomerInvoice).pdfUrl}
      external
      representation="button"
      variant="tertiary"
      download
      isFullwidth
      leadingIcon="download"
      size="small"
    >
      {(document as CustomerInvoice).invoiceNumber}
    </Link>
  )

const Options = ({ document }: OptionsProps) => {
  // i18n
  const { t } = useTranslation('invoices')

  // Constants
  const { isCustomerInvoice } = getDocumentData(document)

  return isCustomerInvoice ? (
    <Cell variant={CellVariant.OPTIONS} title={t('cells.moreOptions')}>
      <TooltipOnClick
        tooltipContent={<ToolTipContent document={document} />}
        tooltipClassName={styles.tooltip}
        tooltipPlacement="bottom-start"
      >
        <Icon name="dots" className={styles.icon} />
      </TooltipOnClick>
    </Cell>
  ) : null
}

export default Options
