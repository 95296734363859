import { StatusProps } from 'pages/App/Billing/Invoices/InvoicesTable/Status/types.ts'
import Cell from 'pages/App/Billing/Invoices/InvoicesTable/Cell/Cell.tsx'
import { CellVariant } from 'pages/App/Billing/Invoices/InvoicesTable/Cell/types.ts'
import { getStatus } from 'pages/App/Billing/Invoices/InvoicesTable/Status/utils.ts'

const Status = ({ document, isDomiciled }: StatusProps) => {
  return <Cell variant={getStatus({ document, isDomiciled }).variant as CellVariant}>{getStatus({ document, isDomiciled }).value}</Cell>
}

export default Status
