import { Row } from 'components/Table/types.ts'

export enum CellType {
  ADDRESS = 'address',
  DESCRIPTION = 'description',
  DATE = 'date',
  AMOUNT = 'amount',
  RUNNING_BALANCE = 'runningBalance',
  STATUS = 'status',
  MATCHING_NUMBER = 'matchingNumber',
  PAYMENT_METHOD = 'paymentMethod'
}

export type TableHeaderValueType = { value: keyof typeof CellType; id?: string }
export type TableHeaderValuesType = { values: TableHeaderValueType[] }
export type TableHeadersType = TableHeaderValueType[] | TableHeaderValuesType[]

export type InvoicesTableProps = {
  headers: TableHeadersType
  rows: Row[]
}
