import { UserTypes } from 'store/auth/types'
import { getIndexRedirectUrl } from 'utils/app'
import { Navigate } from 'react-router'
import AuthContainer from 'containers/auth/AuthContainer'
import { routes } from 'types/routes'
import Login from 'pages/Auth/Login/Login'
import Register from 'pages/Auth/Register/Register'
import ResetPassword from 'pages/Auth/ResetPassword/ResetPassword'
import ProcessToken from 'pages/Auth/ProcessToken/ProcessToken'
import ProtectedRoute from 'components/ProtectedRoute/ProtectedRoute'
import SalesContainer from 'containers/sales/SalesContainer'
import Home from 'pages/Sales/Home/Home'
import AppContainer from 'containers/app/AppContainer'
import Welcome from 'pages/App/Welcome/Welcome'
import CustomerEmail from 'pages/App/CustomerEmail/CustomerEmail'
import Overview from 'pages/App/Overview/Overview'
import General from 'pages/App/Overview/General/General'
import ContractTab from 'pages/App/Contract/ContractTab/ContractTab.tsx'
import Producer from 'pages/App/Producer/Producer'
import Billing from 'pages/App/Billing/Billing'
import Invoices from 'pages/App/Billing/Invoices/Invoices'
import Instalment from 'pages/App/Billing/Instalment/Instalment'
import BillingCycles from 'pages/App/Billing/BillingCycles/BillingCycles'
import Consumption from 'pages/App/Consumption/Consumption'
import User from 'pages/App/User/User'
import Support from 'pages/App/Support/Support'
import EventLog from 'pages/App/EventLog/EventLog'
import { createBrowserRouter } from 'react-router-dom'
import EnergyOverview from 'pages/App/Consumption/EnergyOverview/EnergyOverview'
import YourConsumption from 'pages/App/Consumption/YourConsumption/YourConsumption'
import MeterReadings from 'pages/App/Consumption/MeterReadings/MeterReadings'
import Personal from 'pages/App/User/Personal/Personal'
import SupportContact from 'pages/App/Support/Contact/Contact'
import Payment from 'pages/App/User/Payment/Payment'
import Faq from 'pages/App/Support/Faq/Faq'
import Add from 'features/contracts/add/Add'
import ContractsIndex from 'features/contracts/index/Index'
import Terminate from 'features/contracts/terminate/Terminate'
import { isAuthenticated } from 'utils/localStorageService'
import Friends from 'pages/App/Friends/Friends'
import OutstandingInvoices from 'pages/App/Billing/Invoices/OutstandingInvoices/OutstandingInvoices.tsx'
import AllInvoices from 'pages/App/Billing/Invoices/AllInvoices/AllInvoices.tsx'
import AllTransactions from 'pages/App/Billing/Invoices/AllTransactions/AllTransactions.tsx'
import SuperUserInvoices from 'pages/App/Billing/Invoices/SuperUserInvoices/SuperUserInvoices.tsx'
import Contract from 'pages/App/Contract/Contract.tsx'
import Contact from 'pages/App/User/Contact/Contact.tsx'
import Move from 'pages/App/Move/Move.tsx'
import Initiation from 'pages/App/Move/Initiation/Initiation.tsx'
import YourAddresses from 'pages/App/Move/YourAddresses/YourAddresses.tsx'
import RootLayout from './RootLayout.tsx'
import { getUserType } from 'utils/userType.ts'
import { RootStore } from 'store/index.ts'
import type { Router as RemixRouter } from '@remix-run/router/dist/router'
import PageLoading from 'components/PageLoading/PageLoading.tsx'
import Details from 'pages/Sales/Details/Details'
import Events from 'pages/Sales/Events/Events'

export const getRoutes = (store: RootStore): RemixRouter => {
  const userType = store.getState().auth.userType

  return createBrowserRouter([
    {
      element: <RootLayout />,
      errorElement: <PageLoading isLoading={false} showError />,
      children: [
        { path: '/', element: <Navigate to={getIndexRedirectUrl(userType)} replace /> },
        {
          path: '/sales',
          element: <Navigate to={isAuthenticated().sales ? routes.SALES_HOME : routes.LOGIN_SALES} replace />
        },
        {
          path: '/superuser',
          element: <Navigate to={isAuthenticated().superuser ? routes.CUSTOMER_EMAIL : routes.SUPER_USER_LOGIN} replace />
        },

        // AUTH
        {
          element: <AuthContainer />,
          children: [
            // LOGIN ROUTES
            ...[routes.LOGIN, routes.SUPER_USER_LOGIN, routes.LOGIN_SALES].map((path) => ({
              path,
              element: <Login />
            })),

            // REGISTER ROUTE
            {
              path: routes.REGISTER,
              element: <Register />
            },

            // RESET PASSWORD ROUTES
            ...[
              routes.ACTIVATE,
              routes.ACTIVATE_SALES,
              routes.REQUEST_NEW_PASSWORD,
              routes.REQUEST_NEW_PASSWORD_SALES,
              routes.RESET_PASSWORD,
              routes.RESET_PASSWORD_SALES
            ].map((path) => ({
              path,
              element: <ResetPassword />
            })),

            // PROCESS TOKEN ROUTES
            ...[routes.ACTIVATE_TOKEN, routes.RESET_PASSWORD_TOKEN, routes.ACTIVATE_TOKEN_SALES, routes.RESET_PASSWORD_TOKEN_SALES].map(
              (path) => ({
                path,
                element: <ProcessToken />
              })
            )
          ]
        },

        // PROTECTED ROUTES (SALES)
        {
          element: <ProtectedRoute userTypes={[UserTypes.SALES]} />,
          children: [
            {
              element: <SalesContainer />,
              errorElement: <PageLoading isLoading={false} showError />,
              children: [
                {
                  path: routes.SALES_HOME,
                  element: <Home />
                },
                {
                  path: routes.SALES_CONTRACTS_ADD,
                  element: <Add />
                },
                {
                  path: routes.SALES_DETAILS,
                  element: <Details />
                },
                {
                  path: routes.SALES_EVENTS,
                  element: <Events />
                }
              ]
            }
          ]
        },

        // PROTECTED ROUTES (APP, SUPER_USER)
        {
          element: <ProtectedRoute userTypes={[UserTypes.CUSTOMER, UserTypes.SUPER_USER]} />,
          errorElement: <PageLoading isLoading={false} showError />,
          children: [
            {
              element: <AppContainer />,
              children: [
                { path: routes.WELCOME, element: <Welcome /> },
                { path: routes.CUSTOMER_EMAIL, element: <CustomerEmail /> },
                {
                  path: routes.OVERVIEW,
                  element: <Overview />,
                  children: [{ path: routes.OVERVIEW_GENERAL, element: <General /> }]
                },
                { path: routes.PRODUCER, element: <Producer /> },
                {
                  path: routes.BILLING,
                  element: <Billing />,
                  children: [
                    {
                      path: routes.BILLING_INVOICES,
                      element: <Invoices />,
                      children: [
                        {
                          index: true,
                          element:
                            getUserType() === UserTypes.SUPER_USER ? (
                              <SuperUserInvoices route={routes.BILLING_SUPER_USER} />
                            ) : (
                              <OutstandingInvoices />
                            )
                        },
                        {
                          path: routes.BILLING_SUPER_USER,
                          element:
                            getUserType() === UserTypes.SUPER_USER ? (
                              <SuperUserInvoices route={routes.BILLING_SUPER_USER} />
                            ) : (
                              <Navigate to={routes.BILLING_OUTSTANDING_INVOICES} replace />
                            )
                        },
                        {
                          path: routes.BILLING_SUPER_USER_CLIENT,
                          element:
                            getUserType() === UserTypes.SUPER_USER ? (
                              <SuperUserInvoices route={routes.BILLING_SUPER_USER_CLIENT} />
                            ) : (
                              <Navigate to={routes.BILLING_OUTSTANDING_INVOICES} replace />
                            )
                        },
                        {
                          path: routes.BILLING_SUPER_USER_DISTRIBUTOR,
                          element:
                            getUserType() === UserTypes.SUPER_USER ? (
                              <SuperUserInvoices route={routes.BILLING_SUPER_USER_DISTRIBUTOR} />
                            ) : (
                              <Navigate to={routes.BILLING_OUTSTANDING_INVOICES} replace />
                            )
                        },
                        { path: routes.BILLING_OUTSTANDING_INVOICES, element: <OutstandingInvoices /> },
                        { path: routes.BILLING_ALL_INVOICES, element: <AllInvoices /> },
                        { path: routes.BILLING_ALL_TRANSACTIONS, element: <AllTransactions /> }
                      ]
                    },
                    { path: routes.BILLING_INSTALMENT, element: <Instalment /> },
                    {
                      path: routes.BILLING_CYCLES,
                      element: <BillingCycles />
                    }
                  ]
                },
                {
                  path: routes.CONSUMPTION,
                  element: <Consumption />,
                  children: [
                    { path: routes.CONSUMPTION_YOUR_CONSUMPTION, element: <YourConsumption /> },
                    { path: routes.CONSUMPTION_METER_READINGS, element: <MeterReadings /> },
                    { path: routes.CONSUMPTION_ENERGY_OVERVIEW, element: <EnergyOverview /> }
                  ]
                },
                {
                  path: routes.FRIENDS,
                  element: <Friends />
                },
                {
                  path: routes.CONTRACT,
                  element: <Contract />,
                  children: [{ path: routes.CONTRACT_MY_CONTRACT, element: <ContractTab /> }]
                },
                {
                  path: routes.USER,
                  element: <User />,
                  children: [
                    { path: routes.USER_PERSONAL, element: <Personal /> },
                    { path: routes.USER_CONTACT, element: <Contact /> },
                    { path: routes.USER_PAYMENT, element: <Payment /> }
                  ]
                },
                {
                  path: routes.MOVE,
                  element: <Move />,
                  children: [
                    { path: routes.MOVE_INITIATION, element: <Initiation /> },
                    { path: routes.MOVE_ADDRESSES, element: <YourAddresses /> }
                  ]
                },
                {
                  path: routes.SUPPORT,
                  element: <Support />,
                  children: [
                    { path: routes.SUPPORT_FAQ, element: <Faq /> },
                    { path: routes.SUPPORT_CONTACT, element: <SupportContact /> }
                  ]
                },
                { path: routes.EVENT_LOG, element: <EventLog /> },
                {
                  path: routes.CONTRACTS,
                  children: [
                    { index: true, element: <ContractsIndex /> },
                    { path: routes.CONTRACTS_ADD, element: <Add /> },
                    { path: routes.CONTRACTS_MOVE, element: <Add /> },
                    { path: routes.CONTRACTS_TERMINATE, element: <Terminate /> }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },

    // FALLBACK ROUTE
    {
      path: '*',
      element: <Navigate to={getIndexRedirectUrl(userType)} />
    }
  ])
}
