import { PropsWithChildren, useEffect } from 'react'
import { useLocation } from 'react-router'
import Appbar from 'components/Appbar/Appbar'
import Tabbar from 'components/Tabbar/Tabbar'
import Sidebar from 'components/Sidebar/Sidebar'
import useWindowSize from 'hooks/useWindowSize'
import { routes } from 'types/routes'
import { AppProps } from './types'
import styles from './App.module.scss'
import { UserTypes } from 'store/auth/types'
import Footer from 'components/Footer/Footer'
import mixpanel from 'mixpanel-browser'
import { useStoreSelector } from 'hooks/store.ts'
import PageLoading from 'components/PageLoading/PageLoading.tsx'
import EnergyTransitionDocumentContextProvider from 'store/react-context/energy-transition-document/EnergyTransitionDocumentContextProvider.tsx'

const App = ({ children, isLoading, logout }: PropsWithChildren<AppProps>) => {
  // REDUX STORE
  const { userError } = useStoreSelector((store) => store.user)
  const { userType } = useStoreSelector((store) => store.auth)

  // React Router
  const { pathname } = useLocation()

  // Window size
  const { isTablet } = useWindowSize()

  // Constants
  const hideSidebarAndSubnav = [routes.WELCOME, routes.CUSTOMER_EMAIL, routes.EVENT_LOG, routes.CONTRACTS, routes.ACTIVATE_TOKEN].some(
    (route) => pathname.includes(route)
  )
  const isContractsFlow = pathname.includes(routes.CONTRACTS)
  const hideContent = isLoading || (userType !== UserTypes.SUPER_USER && userError)

  // Define mixpanel super property
  useEffect(() => {
    mixpanel.register({
      userType: userType
    })
  }, [userType])

  return isContractsFlow ? (
    // Contracts layout is inside the pages
    <EnergyTransitionDocumentContextProvider>
      {hideContent ? <PageLoading {...{ isLoading }} /> : children}
    </EnergyTransitionDocumentContextProvider>
  ) : (
    <>
      <Appbar notFixed={hideSidebarAndSubnav} logout={logout} />

      <main className={styles.container}>
        {hideContent ? (
          <PageLoading {...{ isLoading }} />
        ) : (
          <>
            {!hideSidebarAndSubnav && (isTablet ? <Tabbar /> : <Sidebar />)}

            {children}

            <Footer isFullwidth={hideSidebarAndSubnav} />
          </>
        )}
      </main>
    </>
  )
}

export default App
