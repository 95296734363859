import classNames from 'classnames'
import styles from 'pages/App/Friends/Friends.module.scss'
import Icon from 'components/Icon/Icon'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Store } from 'store/types'
import { FACEBOOK_APP_ID } from 'constants/envs'
import { LinkAndShareCardProps } from 'pages/App/Friends/components/LinkAndShareCard/types'
import parse from 'html-react-parser'
import Card from 'components/Card/Card.tsx'
import { Button } from '@boltenergy-be/design-system'
import Link from 'components/Link/Link.tsx'
import { useEffect, useState } from 'react'

const LinkAndShareCard = ({ amount, shareLink = '', hasError }: LinkAndShareCardProps) => {
  // i18n
  const { t } = useTranslation(['referral', 'common'])

  // Redux
  const { info } = useSelector((store: Store) => store.user)
  const userName = info?.firstName

  // Local state
  const [copied, setCopied] = useState<boolean>(false)

  /**
   * Copies the share link to the users clipboard
   */
  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(shareLink)
    setCopied(true)
  }

  // Revert copied state after 5 seconds
  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 5000)
    }
  }, [copied])

  // %0D%0A equals new line in email
  const sharing = {
    subject: t('share.subject', {
      firstName: userName,
      reduction: amount
    }),
    body: t('share.body', { reduction: amount }) + ` ${shareLink}`
  }

  // for messenger app: messenger://share?link=https%3A%2F%2Fwww.youtube.com&app_id=1234567890
  // for browser: https://www.facebook.com/dialog/share?link=${encodeURI(shareLink)}&app_id=${messengerAppId}
  const messengerShareLink = `https://www.facebook.com/dialog/share?href=${encodeURIComponent(shareLink)}&app_id=${FACEBOOK_APP_ID}`

  // for whatsapp app: whatsapp://send?text=
  // for whatsapp web: https://api.whatsapp.com/send/?text=
  const whatsappShareLink = `https://api.whatsapp.com/send/?text=${encodeURIComponent(sharing.subject as string)}%20${encodeURIComponent(
    shareLink
  )}`

  return (
    <Card as="section" className={styles['link-card']}>
      <Card.Title variant="h4">{t('share.heading')}</Card.Title>

      {hasError ? (
        <div>
          <p>{parse(t('common:error'))}</p>
        </div>
      ) : (
        <div className={styles['link-card-wrapper']}>
          <form>
            <div className={classNames('form-group', styles['form-group'])}>
              <label htmlFor="link" className={classNames(styles.bold)}>
                {t('share.link.label')}
              </label>
              <input id="link" name="link" className="form-control" value={shareLink} readOnly={true} />
              <Button type="button" onClick={copyToClipboard}>
                {t(`share.link.${copied ? 'copied' : 'copy'}`)}
              </Button>
            </div>
          </form>
          <div className={styles['link-card-share']}>
            <p className={classNames(styles.bold)}>{t('share.orThrough')}</p>
            <ul className={styles['share-buttons']}>
              <li>
                <Link
                  href={`mailto:?subject=${encodeURIComponent(sharing.subject as string)}&body=${encodeURIComponent(sharing.body)}`}
                  as="a"
                  representation="button"
                  className={styles['share-btn']}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icon name="email" color="currentColor" size={20} />
                </Link>
              </li>
              <li>
                <Link
                  href={messengerShareLink}
                  as="a"
                  representation="button"
                  className={styles['share-btn']}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icon name="messenger" color="currentColor" size={20} />
                </Link>
              </li>
              <li>
                <Link
                  href={whatsappShareLink}
                  as="a"
                  representation="button"
                  className={styles['share-btn']}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icon name="whatsapp" color="currentColor" size={20} />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      )}
    </Card>
  )
}

export default LinkAndShareCard
