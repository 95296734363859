import { CellVariant } from 'pages/App/Billing/Invoices/InvoicesTable/Cell/types.ts'
import { CustomerInvoice } from 'types/types.ts'
import Cell from 'pages/App/Billing/Invoices/InvoicesTable/Cell/Cell.tsx'
import { getDocumentData } from 'pages/App/Billing/Invoices/hooks/utils.tsx'
import DownLoadIcon from 'components/Icon/MultiPathIcons/DownLoadIcon.tsx'
import { InvoiceDownloadProps } from 'pages/App/Billing/Invoices/InvoicesTable/InvoiceDownload/types.ts'
import mixpanel from 'mixpanel-browser'
import { MX_INVOICE_FLOW } from 'constants/trackingIds'

const InvoiceDownload = ({ document }: InvoiceDownloadProps) => {
  // Constants
  const { isCustomerInvoice } = getDocumentData(document)

  return (
    <Cell variant={CellVariant.INVOICE_DOWNLOAD}>
      {isCustomerInvoice && !!(document as CustomerInvoice).pdfUrl && (
        <>
          <DownLoadIcon />
          <a
            href={(document as CustomerInvoice).pdfUrl}
            download={true}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              mixpanel.track(MX_INVOICE_FLOW.downloadPdf)
            }}
          >
            {(document as CustomerInvoice).invoiceNumber}
          </a>
        </>
      )}
    </Cell>
  )
}

export default InvoiceDownload
