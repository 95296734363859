import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

dayjs.extend(isSameOrBefore)

/**
 * Returns an array of formatted dates between two given dates, looped by the given unit
 *
 * @param {string} startDate
 * @param {string} endDate
 * @param {dayjs.ManipulateType} unit
 * @returns {string[]}
 */
export const getArrayOfFormattedDatesBetweenDates = (startDate: string, endDate: string, unit: dayjs.ManipulateType): string[] => {
  const results = []

  for (let i = 0; i <= dayjs(endDate).diff(startDate, unit); i++) {
    results.unshift(dayjs(startDate).add(i, unit).toISOString())
  }

  return results
}
