import { Table } from 'components/Table/Table.tsx'
import useWindowSize from 'hooks/useWindowSize.tsx'
import { CellType, InvoicesTableProps, TableHeaderValueType } from 'pages/App/Billing/Invoices/InvoicesTable/types.ts'
import styles from './InvoiceTable.module.scss'
import { useState } from 'react'
import { paginate, sortRowsByKey } from 'pages/App/Billing/Invoices/utils.ts'
import { invoicesTableBreakpoint } from 'pages/App/Billing/Invoices/constants.ts'
import LoadingSkeleton from 'components/LoadingSkeleton/LoadingSkeleton.tsx'
import StackedInvoicesView from 'pages/App/Billing/Invoices/InvoicesTable/StackedInvoicesView/StackedInvoicesView.tsx'
import { useGetCustomerAccountingQuery } from 'store/queries/bolt-api'
import { useStoreSelector } from 'hooks/store.ts'
import { SortingDirection } from 'components/Table/types.ts'
import { Pagination } from '@boltenergy-be/design-system'

const InvoicesTable = ({ headers, rows }: InvoicesTableProps) => {
  // Window size
  const { isSmaller } = useWindowSize(invoicesTableBreakpoint)

  // state
  const [currentPage, setCurrentPage] = useState(1)
  const [sorting, setSorting] = useState({ id: CellType.DATE as string, direction: SortingDirection.DESC })

  // Redux
  const { selectedCustomer } = useStoreSelector((store) => store.user)
  const { isLoading, isFetching } = useGetCustomerAccountingQuery({
    customerNumber: selectedCustomer
  })

  // constants
  const pages = paginate(rows.sort(sortRowsByKey(sorting?.id, sorting?.direction)), 10)

  // Loading
  if (isLoading || isFetching) {
    const placeholderHeaders = Array.from({ length: 4 }, (idx: number) => ({
      value: <LoadingSkeleton.Paragraph key={idx} lines={1} />,
      id: ''
    }))
    const placeholderCells = Array.from({ length: 4 }, () => ({ value: <LoadingSkeleton.Paragraph lines={1} /> }))
    const placeholderRows = Array.from({ length: 4 }, () => ({ cells: placeholderCells }))

    return (
      <LoadingSkeleton>
        {isSmaller ? (
          <StackedInvoicesView rows={placeholderRows} />
        ) : (
          <Table headers={placeholderHeaders as unknown as TableHeaderValueType[]} rows={placeholderRows} className={styles.table} />
        )}
      </LoadingSkeleton>
    )
  }

  return (
    <div>
      {isSmaller ? (
        <StackedInvoicesView rows={pages[currentPage - 1]} />
      ) : (
        <Table
          headers={headers as unknown as TableHeaderValueType[]}
          rows={pages[currentPage - 1]}
          className={styles.table}
          onSort={setSorting}
          sorting={sorting}
        />
      )}
      {pages.length > 1 && (
        <Pagination
          pagesLength={pages.length}
          activePageNumber={currentPage}
          onPageChange={(page) => setCurrentPage(page)}
          className={styles.pagination}
          maxWidth={320}
        />
      )}
    </div>
  )
}

export default InvoicesTable
