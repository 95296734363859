import styles from './Invoices.module.scss'
import classNames from 'classnames'
import InvoicesStatusBanner from 'pages/App/Billing/Invoices/InvoicesStatusBanner/InvoicesStatusBanner.tsx'
import { useStoreSelector } from 'hooks/store.ts'
import { hasMultipleActiveElectricityContracts } from 'utils/contracts.ts'
import Icon from 'components/Icon/Icon.tsx'
import { useTranslation } from 'react-i18next'
import Card from 'components/Card/Card.tsx'
import { Link, useNavigate } from 'react-router-dom'
import { routes } from 'types/routes.ts'
import { UserTypes } from 'store/auth/types.ts'
import { AccountingEntity } from 'types/types.ts'
import { GetCustomerAccountingResponse } from 'store/queries/bolt-api/types.ts'
import { useGetCustomerAccountingQuery } from 'store/queries/bolt-api'
import { Tab } from 'components/Subnav/types.ts'
import Subnav from 'components/Subnav/Subnav.tsx'
import { Outlet } from 'react-router'
import FeedbackWidget from 'components/FeedbackWidget/FeedbackWidget.tsx'
import useOpenFeedbackWidget from 'hooks/useOpenFeedbackWidget.ts'
import { WidgetSlug } from 'types/feedbackWidget.ts'

const Invoices = () => {
  // i18n
  const { t } = useTranslation('invoices')

  // Custom hook
  const { feedbackWidgetData, openFeedbackWidget } = useOpenFeedbackWidget(10, WidgetSlug.INVOICES)

  // React Router
  const navigate = useNavigate()

  // Redux
  const { userType } = useStoreSelector((store) => store.auth)
  const { selectedCustomer, customers } = useStoreSelector((store) => store.user)
  const { data = {} as GetCustomerAccountingResponse } = useGetCustomerAccountingQuery({
    customerNumber: selectedCustomer
  })

  // Constants
  const customer = customers[selectedCustomer]
  const isSuperUser = userType === UserTypes.SUPER_USER
  const userIsSupplier = !!data.documents?.find((doc) => doc.accountingEntity === AccountingEntity.SUPPLIER)
  const userIsCustomer = !!data.documents?.find((doc) => doc.accountingEntity === AccountingEntity.CUSTOMER)
  const userIsCustomerAndSupplier = userIsSupplier && userIsCustomer
  const subNavTabs: Tab[] = [
    isSuperUser &&
      !userIsCustomerAndSupplier && {
        label: t('invoicesOutlet.superuser.title'),
        path: routes.BILLING_SUPER_USER
      },
    isSuperUser &&
      userIsCustomerAndSupplier && {
        label: t('invoicesOutlet.superuserClient.title'),
        path: routes.BILLING_SUPER_USER_CLIENT
      },
    isSuperUser &&
      userIsCustomerAndSupplier && {
        label: t('invoicesOutlet.superuserDistributor.title'),
        path: routes.BILLING_SUPER_USER_DISTRIBUTOR
      },
    { label: t('invoicesOutlet.outstandingInvoices.title'), path: routes.BILLING_OUTSTANDING_INVOICES },
    { label: t('invoicesOutlet.allInvoices.title'), path: routes.BILLING_ALL_INVOICES },
    { label: t('invoicesOutlet.allTransactions.title'), path: routes.BILLING_ALL_TRANSACTIONS }
  ].filter(Boolean) as Tab[]

  return (
    <>
      <div className={classNames('container', styles.container)}>
        {hasMultipleActiveElectricityContracts(customers) && (
          <div className={styles.information}>
            <Icon name="info" />
            <p>
              {t('invoicesInformation.text')} {selectedCustomer}
            </p>
          </div>
        )}

        <InvoicesStatusBanner />

        <Card as="section" className={styles.outlet}>
          <Subnav tabs={subNavTabs} activeTab={(idx: number) => navigate(subNavTabs[idx].path)} className={styles.subnav} noPushElement />
          <Outlet />
        </Card>

        {!customer?.paymentDetails?.directDebit && (
          <Card as="section">
            <Card.Title as="h2">{t('paymentMethodBanner.title')}</Card.Title>
            <p className="mb-300">{t('paymentMethodBanner.text')}</p>
            <Link to={routes.USER_PAYMENT}>{t('paymentMethodBanner.linkText')}</Link>
          </Card>
        )}
      </div>
      {feedbackWidgetData?.isActive && <FeedbackWidget id={feedbackWidgetData._id} openFeedbackWidget={openFeedbackWidget} />}
    </>
  )
}

export default Invoices
