import useInvoicesTable from 'pages/App/Billing/Invoices/hooks/useInvoicesTable.tsx'
import { routes } from 'types/routes.ts'
import NoInvoices from 'pages/App/Billing/Invoices/NoInvoices/NoInvoices.tsx'
import InvoicesTable from 'pages/App/Billing/Invoices/InvoicesTable/InvoicesTable.tsx'

const AllInvoices = () => {
  // hooks
  const { headers, rows } = useInvoicesTable({ route: routes.BILLING_ALL_INVOICES })

  return rows.length ? <InvoicesTable headers={headers} rows={rows} /> : <NoInvoices route={routes.BILLING_ALL_INVOICES} />
}

export default AllInvoices
