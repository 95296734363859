import styles from 'components/Table/Table.module.scss'
import { Button } from '@boltenergy-be/design-system'
import { SortingDirection, TableHeaderProps } from 'components/Table/types.ts'
import classNames from 'classnames'
import Icon from 'components/Icon/Icon.tsx'
import Dropdown from 'components/Dropdown/Dropdown.tsx'
import { TableHeaderValuesType, TableHeaderValueType } from 'pages/App/Billing/Invoices/InvoicesTable/types.ts'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DropdownItems } from 'components/Dropdown/types.ts'

const TableHead = ({ header, sorting, onSort }: TableHeaderProps) => {
  // i18n
  const { t } = useTranslation('invoices')

  // State
  const [headerId, setHeaderId] = useState<string | undefined>(
    (header as TableHeaderValueType)?.id ?? (header as unknown as TableHeaderValuesType)?.values?.[0]?.id
  )

  // Handlers
  const handleSelect = (_: string, item: any) => {
    setHeaderId(item.id)
  }

  return (
    <th>
      <div className={styles['th-layout']}>
        {!!(header as TableHeaderValueType)?.value && <div>{(header as TableHeaderValueType).value}</div>}
        {!!(header as unknown as TableHeaderValuesType)?.values?.length && (
          <Dropdown
            header={t('tableHeaders.sorting')}
            items={
              {
                ...(header as unknown as TableHeaderValuesType).values.map((headerValue: TableHeaderValueType) => ({
                  text: headerValue.value,
                  ...headerValue
                }))
              } as unknown as DropdownItems
            }
            label="1"
            onSelect={handleSelect}
          />
        )}

        {!!headerId && (
          <div className={styles['sorting-controls']}>
            <Button
              variant={sorting?.id === headerId && sorting?.direction === SortingDirection.ASC ? 'secondary' : 'tertiary'}
              className={classNames(styles['control-button'], {
                [styles.active]: sorting?.id === headerId && sorting?.direction === SortingDirection.ASC
              })}
              onClick={() => onSort?.({ id: headerId, direction: SortingDirection.ASC })}
            >
              <Icon name="arrowUpDropCircle" />
            </Button>
            <Button
              variant={sorting?.id === headerId && sorting?.direction === SortingDirection.DESC ? 'secondary' : 'tertiary'}
              className={classNames(styles['control-button'], {
                [styles.active]: sorting?.id === headerId && sorting?.direction === SortingDirection.DESC
              })}
              onClick={() => onSort?.({ id: headerId, direction: SortingDirection.DESC })}
            >
              <Icon name="arrowDownDropCircle" />
            </Button>
          </div>
        )}
      </div>
    </th>
  )
}

export default TableHead
