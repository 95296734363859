import InvoicesTable from 'pages/App/Billing/Invoices/InvoicesTable/InvoicesTable.tsx'
import useInvoicesTable from 'pages/App/Billing/Invoices/hooks/useInvoicesTable.tsx'
import NoInvoices from 'pages/App/Billing/Invoices/NoInvoices/NoInvoices.tsx'
import { invoicesRoute } from 'pages/App/Billing/Invoices/hooks/types.ts'

const SuperUserInvoices = ({ route }: { route: invoicesRoute }) => {
  // hooks
  const { headers, rows } = useInvoicesTable({ route: route })

  return rows.length ? <InvoicesTable headers={headers} rows={rows} /> : <NoInvoices route={route} />
}

export default SuperUserInvoices
