export enum routes {
  // AUTH (customer)
  LOGIN = '/login',
  REGISTER = '/register',

  ACTIVATE_TOKEN = '/activate/:token',
  ACTIVATE = '/activate',

  REQUEST_NEW_PASSWORD = '/new-password',
  RESET_PASSWORD_TOKEN = '/reset/:token',
  RESET_PASSWORD = '/reset',

  // AUTH (sales)
  LOGIN_SALES = '/sales/login',
  ACTIVATE_SALES = '/sales/activate',
  ACTIVATE_TOKEN_SALES = '/sales/activate/:token',
  REQUEST_NEW_PASSWORD_SALES = '/sales/new-password',
  RESET_PASSWORD_TOKEN_SALES = '/sales/reset/:token',
  RESET_PASSWORD_SALES = '/sales/reset',

  // AUTH (superuser)
  SUPER_USER_LOGIN = '/superuser/login',

  // CUSTOMER PORTAL
  WELCOME = '/welcome',
  CUSTOMER_EMAIL = '/customer-email',

  OVERVIEW = '/overview',
  OVERVIEW_GENERAL = '/overview/general',

  PRODUCER = '/producer',

  BILLING = '/billing',
  BILLING_INVOICES = '/billing/invoices',
  BILLING_SUPER_USER = '/billing/invoices/super-user',
  BILLING_SUPER_USER_CLIENT = '/billing/invoices/superuser-client',
  BILLING_SUPER_USER_DISTRIBUTOR = '/billing/invoices/superuser-distributor',
  BILLING_OUTSTANDING_INVOICES = '/billing/invoices/outstanding-invoices',
  BILLING_ALL_INVOICES = '/billing/invoices/all-invoices',
  BILLING_ALL_TRANSACTIONS = '/billing/invoices/all-transactions',
  BILLING_INSTALMENT = '/billing/instalment',
  BILLING_SETTLEMENT = '/billing/settlement',
  BILLING_CYCLES = '/billing/billing-cycles',

  CONSUMPTION = '/consumption',
  CONSUMPTION_YOUR_CONSUMPTION = '/consumption/your-consumption',
  CONSUMPTION_METER_READINGS = '/consumption/meter-readings',
  CONSUMPTION_ENERGY_OVERVIEW = '/consumption/energy-overview',

  FRIENDS = '/friends',

  CONTRACT = '/contract',
  CONTRACT_MY_CONTRACT = '/contract/my-contract',

  USER = '/user',
  USER_PERSONAL = '/user/personal',
  USER_CONTACT = '/user/contact',
  USER_PAYMENT = '/user/payment',

  MOVE = '/move',
  MOVE_INITIATION = '/move/initiation',
  MOVE_ADDRESSES = '/move/addresses',

  SUPPORT = '/support',
  SUPPORT_FAQ = '/support/faq',
  SUPPORT_CONTACT = '/support/contact',

  EVENT_LOG = '/event-log',

  CONTRACTS = '/contracts',
  CONTRACTS_ADD = '/contracts/add',
  CONTRACTS_MOVE = '/contracts/move',
  CONTRACTS_TERMINATE = '/contracts/terminate',

  // SALES ROUTES
  SALES_HOME = '/sales/home',
  SALES_CONTRACTS_ADD = '/sales/contracts/add',
  SALES_DETAILS = '/sales/details/:contactId',
  SALES_EVENTS = '/sales/events',

  // DEPRECATED ROUTES - used as redirects
  DEPRECATED_ENERGY_OVERVIEW = '/energy-overview'
}
