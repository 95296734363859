import ContactDetails from 'components/SalesDetails/ContactDetails'
import classNames from 'classnames'
import styles from './Details.module.scss'
import { useParams } from 'react-router-dom'
import { useGetSalesContactQuery, useGetSalesParametersByKeyQuery } from 'store/queries/bolt-api'
import { ButtonLoadingIndicator } from '@boltenergy-be/design-system'
import { Tab } from 'components/Subnav/types'
import { useEffect, useState } from 'react'
import AccountDetails from 'components/SalesDetails/AccountDetails'
import PropositionDetails from 'components/SalesDetails/PropositionDetails'
import { Account } from 'types/sales'

const Details = () => {
  //REACT ROUTER
  const { contactId } = useParams<{ contactId: string }>()

  // FETCH DATA
  const { data: contact, isLoading, isFetching } = useGetSalesContactQuery(contactId!)
  const { data: parameters } = useGetSalesParametersByKeyQuery({ key: 'case_subject' })

  // LOCAL STATE
  const [activeHousehold, setActiveHousehold] = useState<Account | undefined>()
  const [activeEditing, setActiveEditing] = useState<string>()

  useEffect(() => {
    if (!isFetching) {
      if (!activeHousehold) {
        setActiveHousehold(contact?.accounts[0])
      } else {
        setActiveHousehold(contact?.accounts?.find((account) => account.salesforceId === activeHousehold?.salesforceId))
      }
    }
  }, [contact, isFetching, isLoading])

  const tabs: Tab[] = []
  contact?.accounts.forEach((account) => {
    tabs.push({ label: account.name, path: '' })
  })

  /**
   * sets the active household to the item selected in the subnav
   *
   * @param {number} idx
   */
  const handleTabClick = (idx: number) => {
    setActiveHousehold(contact?.accounts[idx])
  }

  return (
    <div className="content">
      {!isLoading ? (
        <div className={classNames('container', styles.details)}>
          {contact && (
            <ContactDetails
              identifier="contactDetails"
              activeEditing={activeEditing}
              setActiveEditing={setActiveEditing}
              contact={contact}
            />
          )}
          {activeHousehold && (
            <>
              <AccountDetails
                identifier="AccountDetails"
                tabs={tabs}
                account={activeHousehold!}
                changeActiveHousehold={handleTabClick}
                activeEditing={activeEditing}
                setActiveEditing={setActiveEditing}
                contactId={contact?.salesforceId}
              />
              {activeHousehold?.opportunities.map((opportunity, index: number) => (
                <PropositionDetails
                  identifier={`PropositionDetails${index}`}
                  key={opportunity.salesforceId}
                  opportunity={opportunity}
                  contract={activeHousehold.billingContracts?.find(
                    (contract) => contract.opportunitySalesforceId === opportunity.salesforceId
                  )}
                  propositionNumber={index + 1}
                  accountId={activeHousehold.salesforceId}
                  contactId={contact?.salesforceId}
                  activeEditing={activeEditing}
                  setActiveEditing={setActiveEditing}
                  customerName={`${activeHousehold?.firstName} ${activeHousehold?.lastName}`}
                  salesParameters={parameters?.parameters}
                />
              ))}
            </>
          )}
        </div>
      ) : (
        <div>
          <ButtonLoadingIndicator />
        </div>
      )}
    </div>
  )
}

export default Details
