import { useInvoicesTableProps, useInvoicesTableReturnType } from 'pages/App/Billing/Invoices/hooks/types.ts'
import { Row } from 'components/Table/types.ts'
import { routes } from 'types/routes.ts'
import useWindowSize from 'hooks/useWindowSize.tsx'
import { invoicesTableBreakpoint, superUserTableHeaders, tableHeaders } from 'pages/App/Billing/Invoices/constants.ts'
import { useStoreSelector } from 'hooks/store.ts'
import { filterDocuments } from 'pages/App/Billing/Invoices/hooks/utils.tsx'
import { getInvoiceRows, getTransactionRows } from 'pages/App/Billing/Invoices/hooks/rows.tsx'
import { useGetCustomerAccountingQuery } from 'store/queries/bolt-api'

/**
 * Returns the table 'headers' and 'rows' for a given route.
 * @param {invoicesRoute} route
 */
const useInvoicesTable = ({ route }: useInvoicesTableProps): useInvoicesTableReturnType => {
  // Window size
  const { isSmaller } = useWindowSize(invoicesTableBreakpoint)

  // REDUX STORE
  const { language } = useStoreSelector((store) => store.app)
  const { customers, selectedCustomer } = useStoreSelector((store) => store.user)
  const { data } = useGetCustomerAccountingQuery({
    customerNumber: selectedCustomer
  })

  // constants
  const filteredDocuments = filterDocuments({ route, documents: data?.documents ?? [] })
  const isDomiciled = customers[selectedCustomer]?.paymentDetails?.directDebit

  switch (route) {
    case routes.BILLING_SUPER_USER:
    case routes.BILLING_SUPER_USER_CLIENT:
    case routes.BILLING_SUPER_USER_DISTRIBUTOR: {
      return {
        headers: superUserTableHeaders(),
        rows: getInvoiceRows({
          documents: filteredDocuments,
          isDomiciled,
          isSuperuser: true,
          language,
          customers,
          selectedCustomer
        }) as Row[]
      }
    }

    case routes.BILLING_OUTSTANDING_INVOICES:
    case routes.BILLING_ALL_INVOICES: {
      return {
        headers: tableHeaders(),
        rows: getInvoiceRows({
          documents: filteredDocuments,
          isDomiciled,
          isSmaller,
          isSuperuser: false,
          language,
          customers,
          selectedCustomer
        }) as Row[]
      }
    }

    case routes.BILLING_ALL_TRANSACTIONS: {
      return {
        headers: tableHeaders(),
        rows: getTransactionRows({
          documents: filteredDocuments,
          isDomiciled: isDomiciled,
          language: language
        })
      }
    }

    default:
      return {
        headers: [],
        rows: []
      }
  }
}

export default useInvoicesTable
