import Slider from '@mui/material/Slider'
import classNames from 'classnames'
import LoadingDots from 'components/LoadingDots/LoadingDots'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { UserTypes } from 'store/auth/types'
import { Contract, ContractStatus, ContractType } from 'types/types'
import { FaqSectionSlugs } from 'types/faq'
import { roundTo, roundToTwoDecimals } from 'utils/number'
import { formatAmount } from 'utils/format'
import styles from './Instalment.module.scss'
import { FieldTypes, InstalmentForm } from './types'
import debounce from 'lodash/debounce'
import InfoBadge from 'components/Badge/InfoBadge/InfoBadge'
import useWindowSize from 'hooks/useWindowSize'
import parse from 'html-react-parser'
import { useBeforeunload } from 'react-beforeunload'
import { WidgetSlug } from 'types/feedbackWidget'
import FeedbackWidget from 'components/FeedbackWidget/FeedbackWidget'
import FooterFaq from 'components/Faq/FooterFaq/FooterFaq'
import { determineAccessRights, getMemoizedSelectedContracts, isActiveContract } from 'utils/contracts'
import { DATE_FORMAT } from 'constants/constants'
import dayjs from 'dayjs'
import { addNewInstalmentToContract } from './utils'
import { getBillShock } from 'store/contracts/thunks'
import { generateBillShockAxiosRequestConfig } from 'api/contracts'
import { newRequest } from 'utils/request'
import { routes } from 'types/routes'
import Bugsnag from '@bugsnag/js'
import { Navigate } from 'react-router'
import { Response } from 'types/request'
import { GetBillShockResponseData } from 'types/billShock'
import { updateInstalment } from 'store/user/thunks/customer'
import { useStoreDispatch, useStoreSelector } from 'hooks/store'
import { useGetFaqBySlugQuery } from 'store/queries/cms-api'
import { Language } from 'store/app/types'
import { checkHasFixedProduct } from 'utils/products'
import Card from 'components/Card/Card.tsx'
import { Banner, Button } from '@boltenergy-be/design-system'
import { ReturnLater } from 'components/ReturnLater/ReturnLater'
import useOpenFeedbackWidget from 'hooks/useOpenFeedbackWidget'

const Instalment = () => {
  // REDUX STORE
  const { language } = useStoreSelector((store) => store.app)
  const { userType } = useStoreSelector((store) => store.auth)
  const {
    billShock: { initialLoaded, loading: billShockLoading, data: billShockData }
  } = useStoreSelector((store) => store.contracts)
  const {
    info: { email },
    loading,
    selectedContracts
  } = useStoreSelector((store) => store.user)
  const dispatch = useStoreDispatch()

  // Contracts
  const { billingContract, electricityContract, gasContract } = getMemoizedSelectedContracts(selectedContracts)

  // Access rights
  const accessRights = determineAccessRights(electricityContract)

  // Redux queries
  const { data: withInstalmentFaq, isLoading: withInstalmentFaqLoading } = useGetFaqBySlugQuery({
    language,
    slug: language === Language.FR ? FaqSectionSlugs.INSTALMENT_WITH_PROPOSITION_FR : FaqSectionSlugs.INSTALMENT_WITH_PROPOSITION_NL
  })
  const { data: withoutInstalmentFaq, isLoading: withoutInstalmentFaqLoading } = useGetFaqBySlugQuery({
    language,
    slug: language === Language.FR ? FaqSectionSlugs.INSTALMENT_WITHOUT_PROPOSITION_FR : FaqSectionSlugs.INSTALMENT_WITHOUT_PROPOSITION_NL
  })

  // i18n
  const { t } = useTranslation('billing')

  // Constants
  const billShockDate = dayjs(billShockData?.computeDate).format(DATE_FORMAT)
  const electricityContractStarted = isActiveContract(electricityContract)
  const currentElectricityInstalment = electricityContractStarted ? Number(electricityContract.detail.instalment || 0) : 0
  const currentGasInstalment = gasContract && isActiveContract(gasContract) ? Number(gasContract?.detail.instalment || 0) : 0
  const currentInstalment = roundToTwoDecimals(currentElectricityInstalment + currentGasInstalment)
  const hasEffectiveGasContract = typeof gasContract !== 'undefined' && gasContract.detail.status === ContractStatus.EFFECTIVE
  const isFixedProduct = checkHasFixedProduct()

  // Local state
  const [billShockAmount, setBillShockAmount] = useState<number | null>(null)
  const [editElecInstalment, setEditElecInstalment] = useState<boolean>(false)
  const [editGasInstalment, setEditGasInstalment] = useState<boolean>(false)
  const [electricityRatio, setElectricityRatio] = useState<number>(0)
  const [gasRatio, setGasRatio] = useState<number>(0)
  const [hoveringOnMark, setHoveringOnMark] = useState<boolean>(false)
  const [instalmentSaved, setInstalmentSaved] = useState<boolean>(false)
  const [maxInstalment, setMaxInstalment] = useState<number>(0)
  const [minInstalment, setMinInstalment] = useState<number>(0)
  const [startFeedbackWidgetTimer, setStartFeedbackWidgetTimer] = useState<boolean>(false)
  const [proposedInstalment, setProposedInstalment] = useState<number>()
  const [refetchingBillShock, setRefetchingBillShock] = useState<boolean>(false)
  const [updatedContracts, setUpdatedContracts] = useState<Contract[] | undefined>()

  // Custom hook
  const { feedbackWidgetData, openFeedbackWidget } = useOpenFeedbackWidget(3, WidgetSlug.INSTALMENT_EDIT, startFeedbackWidgetTimer)

  // FAQ
  const instalmentFaqCategory = proposedInstalment ? withInstalmentFaq?.category : withoutInstalmentFaq?.category

  // Window size
  const { isMobile } = useWindowSize()

  // React hook form
  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
    setValue,
    watch,
    trigger
  } = useForm<InstalmentForm>({
    mode: 'onChange',
    defaultValues: {
      totalInstalment: Number(currentInstalment),
      electricityInstalment: Number(currentElectricityInstalment),
      gasInstalment: Number(currentGasInstalment)
    },
    shouldFocusError: false
  })

  // React Hook Form Watch values
  const watchElectricityInstalment = watch('electricityInstalment')
  const watchGasInstalment = watch('gasInstalment')
  const watchTotalInstalment = watch('totalInstalment')

  /**
   * Calculate instalment data when bill shock stopped loading
   */
  useEffect(() => {
    if (!billShockLoading) calculateInstalmentData()
  }, [billShockLoading])

  // Successful instalment edit:
  // - reload billshock
  // - show feedback widget 3 seconds after
  useEffect(() => {
    if (!loading && instalmentSaved) {
      // Refetch billshock
      dispatch(
        getBillShock({
          billingContractId: billingContract.id,
          email,
          electricityContract: updatedContracts?.find((ctr) => ctr.type === ContractType.ELECTRICITY) ?? electricityContract,
          gasContract: updatedContracts?.find((ctr) => ctr.type === ContractType.GAS && ctr.detail.status === ContractStatus.EFFECTIVE)
        })
      )

      // Scroll to top of form on mobile so feedback widget does not block success message
      if (isMobile) {
        const form = document.getElementById('form')

        if (form) {
          window.scrollTo({
            behavior: 'smooth',
            left: 0,
            top: form.offsetTop + 30
          })
        }
      }

      setStartFeedbackWidgetTimer(true)
    }
  }, [loading])

  // Shows dialog when user tries to reload the page without confirming
  useBeforeunload((e: any) => {
    if (currentInstalment !== watchTotalInstalment && !instalmentSaved) {
      e.preventDefault()
    }
  })

  /**
   * Initializes the instalment page data fetch & sets the correct values in the store
   */
  const calculateInstalmentData = async () => {
    let elRatio = 0.5
    let ngRatio = 0.5
    let minimumInstalment = 20

    if (billShockData) {
      const { instalment, settlement } = billShockData

      // Ratio's
      elRatio = instalment.estimatedRatio.electricity
      ngRatio = instalment.estimatedRatio.gas

      // Set the min instalment (if not super user)
      if (userType !== UserTypes.SUPER_USER) {
        minimumInstalment = instalment.minimum

        // If min instalment is lower than 20, set it to 20 (20 = absolute minimum)
        if (minimumInstalment < 20) {
          minimumInstalment = 20
        } else {
          minimumInstalment = roundTo(5, instalment.minimum)
        }
      }
      setMinInstalment(minimumInstalment)

      // Set the max instalment
      const proposedInstalmentTimesTwo = instalment.proposed > 0 ? roundTo(50, instalment.proposed * 1.5) : 20
      const maxInstalmentFromProposed =
        proposedInstalmentTimesTwo < 500 ? (instalment.current > 500 ? instalment.current * 1.5 : 500) : proposedInstalmentTimesTwo
      if (maxInstalment === 0) {
        setMaxInstalment(
          Math.round(
            instalment.proposed > 0 && instalment.proposed < currentInstalment
              ? currentInstalment * 1.5
              : roundTo(25, maxInstalmentFromProposed)
          )
        )
      }

      // Set billshock amount & proposed instalment (if higher than current instalment)
      setBillShockAmount(settlement.billShockAmount)
      if (instalment.proposed > currentInstalment) {
        setProposedInstalment(instalment.proposed)
      }
    } else {
      // NO BILLSHOCK DATA: no ratio's, no min/max, no proposed instalment, no billshock amount
      // elRatio = ratio calculated based on currentInstalment EXCEPT when currentInstalment is 0, than 0.5
      // ngRatio = ratio calculated based on currentInstalment EXCEPT when currentInstalment is 0, than 0.5
      // min = currentInstalment * 0.8 EXCEPT when superuser or when currentInstalment is 0 (= not returned from API), than 20
      // max = currentInstalment * 2 EXCEPT if smaller than 500, than 500
      // proposedInstalment & billshockAmount: not shown

      // Ratio's
      if (currentInstalment !== 0) {
        elRatio = currentElectricityInstalment / currentInstalment
        ngRatio = currentGasInstalment / currentInstalment
      }
      // If only gas, set elRatio to 1
      if (!hasEffectiveGasContract) {
        elRatio = 1
        ngRatio = 0 // fallback
      }

      // Set the min instalment if not super user
      if (userType !== UserTypes.SUPER_USER && currentInstalment !== 0) minimumInstalment = roundTo(5, currentInstalment * 0.8)

      // Set the max instalment
      const currentInstalmentTimesTwo = currentInstalment * 1.5
      if (maxInstalment === 0) {
        setMaxInstalment(roundTo(50, Math.round(currentInstalmentTimesTwo < 500 ? 500 : currentInstalmentTimesTwo)))
      }
    }

    setElectricityRatio(elRatio)
    setGasRatio(ngRatio)
    if (minInstalment === 0) {
      setMinInstalment(minimumInstalment)
    }
  }

  /**
   * Handles the submit after validation by RHF
   *
   * @param data
   */
  const onSubmit = (data: InstalmentForm) => {
    const { electricityInstalment, gasInstalment } = data

    const updatedElectricityContract = addNewInstalmentToContract(electricityContract, electricityInstalment)

    let updatedGasContract
    if (hasEffectiveGasContract && gasInstalment) {
      updatedGasContract = addNewInstalmentToContract(gasContract, gasInstalment)
    }

    setInstalmentSaved(true)

    // Save the contracts
    dispatch(
      updateInstalment({
        billingContractId: billingContract.id,
        electricity: updatedElectricityContract,
        gas: updatedGasContract
      })
    )
    setUpdatedContracts([updatedElectricityContract, ...(updatedGasContract ? [updatedGasContract] : [])])
  }

  /**
   * Refetches bill shock data
   */
  const refetchBillShock = async (updatedElectricityContract: Contract, updatedGasContract?: Contract) => {
    setRefetchingBillShock(true)

    const options = generateBillShockAxiosRequestConfig(billingContract.id, email, updatedElectricityContract, updatedGasContract)
    const { success, data }: Response<GetBillShockResponseData> = await newRequest(options)

    if (success && data) {
      if (data.billShock) {
        setBillShockAmount(data.billShock.settlement.billShockAmount)

        if (data.billShock.instalment.proposed > currentInstalment) {
          setProposedInstalment(data.billShock.instalment.proposed)
        }
      }
    } else {
      Bugsnag.notify('Error while fetching bill shock data or empty response')
    }

    setRefetchingBillShock(false)
  }

  const refetchBillShockDebounce = useRef(debounce(refetchBillShock, 500, { leading: false, trailing: true }))

  /**
   * Handles the instalment change event (both total & fuel fields)
   *
   * @param {number} [newTotalInstalment]
   * @param {FieldTypes} fieldType
   */
  const handleInstalmentChange = (fieldType: FieldTypes, newTotalInstalment?: number) => {
    setInstalmentSaved(false)

    const totalInstalment = newTotalInstalment || 0

    let electricityInstalment = watchElectricityInstalment
    let gasInstalment = watchGasInstalment

    if (newTotalInstalment) {
      electricityInstalment = electricityRatio === 0 ? 10 : roundToTwoDecimals(totalInstalment * electricityRatio)
      gasInstalment = electricityRatio === 0 ? totalInstalment - 10 : roundToTwoDecimals(totalInstalment * gasRatio)
    }

    // The field that was changed was the "totalInstalment" field
    if (fieldType === FieldTypes.TOTAL) {
      setValue('totalInstalment', totalInstalment)
      setValue('electricityInstalment', electricityInstalment)
      setValue('gasInstalment', gasInstalment)
      trigger(['totalInstalment', 'electricityInstalment', 'gasInstalment'])
    }

    // The field that was changed was the "electricityInstalment" or "gasInstalment" field
    if (fieldType === FieldTypes.FUEL) {
      setValue('totalInstalment', roundToTwoDecimals(Number(watchElectricityInstalment) + Number(watchGasInstalment)) || 0)
      trigger('totalInstalment')
    }

    // Refetch billShock (if it was available during pageload)
    if (billShockAmount !== null) {
      const updatedElectricityContract = addNewInstalmentToContract(electricityContract, electricityInstalment)

      let updatedGasContract
      if (hasEffectiveGasContract && gasInstalment) {
        updatedGasContract = addNewInstalmentToContract(gasContract, gasInstalment)
      }

      refetchBillShockDebounce.current(updatedElectricityContract, updatedGasContract)
    }
  }

  // Slider marks
  const marks = []
  if (proposedInstalment) {
    marks.push({
      value: proposedInstalment,
      label: `€ ${formatAmount(proposedInstalment)}`
    })
  }

  // Custom component for mark on slider
  const CustomMark = (props: any) => {
    const { children, style, className } = props

    return (
      <button
        className={classNames(className, styles['mark-proposed-instalment'], {
          [styles.hover]: hoveringOnMark
        })}
        style={style}
        onMouseDown={(e) => {
          e.preventDefault()
          handleInstalmentChange(FieldTypes.TOTAL, Number(proposedInstalment))
        }}
        onMouseEnter={() => setHoveringOnMark(true)}
        onMouseLeave={() => setHoveringOnMark(false)}
      >
        {children}
      </button>
    )
  }

  // Custom component for mark label on slider
  const CustomMarkLabel = (props: any) => {
    const { children, style, className } = props

    return (
      <button
        className={classNames(className, styles['mark-label-proposed-instalment'], { [styles.hover]: hoveringOnMark })}
        style={style}
        onMouseDown={(e) => {
          e.preventDefault()
          handleInstalmentChange(FieldTypes.TOTAL, Number(proposedInstalment))
        }}
        onMouseEnter={() => setHoveringOnMark(true)}
        onMouseLeave={() => setHoveringOnMark(false)}
      >
        <div className={styles.positioner}>
          <div className={styles.label}>{t('instalment.proposedInstalment')}</div>
          <div className={styles.value}>{children}</div>
        </div>
      </button>
    )
  }

  return !accessRights.instalment.canAccess ? (
    <Navigate to={routes.BILLING_INVOICES} replace />
  ) : !accessRights.instalment.showContent ? (
    <ReturnLater description={t('instalment.returnLater')} />
  ) : (
    <>
      <div className="container">
        <Card className={styles.card}>
          <Card.Title as="h1" className={styles.title}>
            {t('instalment.title')}
          </Card.Title>
          {accessRights.billingCycles.canAccess && accessRights.billingCycles.showContent && !initialLoaded ? (
            <div className={styles['loading-container']}>
              <LoadingDots />
            </div>
          ) : (
            <>
              <p className={classNames('card-description', styles.subtitle)}>
                {currentInstalment !== 0
                  ? `${t('instalment.description.withCurrentInstalment', {
                      min: formatAmount(minInstalment),
                      max: formatAmount(maxInstalment),
                      current: formatAmount(currentInstalment)
                    })} ${proposedInstalment ? t('instalment.description.withProposedInstalment') : ''} ${t(
                      'instalment.description.warning'
                    )}`
                  : `${t('instalment.descriptionNoCurrentInstalment', '', {
                      min: formatAmount(minInstalment),
                      max: formatAmount(maxInstalment)
                    })} ${t('instalment.description.warning')}`}
              </p>

              <div className={styles.container}>
                <div className={styles['slider-wrapper']}>
                  <div className={styles['slider-container']}>
                    <Slider
                      className={styles['instalment-slider']}
                      min={minInstalment}
                      max={maxInstalment}
                      step={5}
                      valueLabelDisplay="on"
                      valueLabelFormat={(value) => `€ ${formatAmount(value)}`}
                      marks={marks}
                      onChange={(_, value) => handleInstalmentChange(FieldTypes.TOTAL, Number(value))}
                      value={watchTotalInstalment}
                      components={{
                        Mark: CustomMark,
                        MarkLabel: CustomMarkLabel
                      }}
                    />
                    <div className={classNames(styles['mark-label'], styles['mark-label-min'])}>
                      <div className={styles.positioner}>€ {formatAmount(minInstalment)}</div>
                    </div>
                    <div className={classNames(styles['mark-label'], styles['mark-label-max'])}>
                      <div className={styles.positioner}>€ {formatAmount(maxInstalment)}</div>
                    </div>
                  </div>
                </div>

                <form id="form" className={styles['instalment-form']} onSubmit={handleSubmit(onSubmit)}>
                  <div className={styles['instalment-info']}>
                    <div className="form-group">
                      <label htmlFor="totalInstalment" className={styles.label}>
                        {t('instalment.newInstalment')}
                      </label>
                      <div className={classNames(styles['input-wrapper'], 'prepend-input-wrapper')}>
                        <span
                          className={classNames('prepend', {
                            [styles['prepend-error']]: errors?.totalInstalment
                          })}
                        >
                          €
                        </span>
                        <input
                          type="number"
                          step="0.01"
                          className={classNames(styles['instalment-input'], styles['total-instalment-input'], 'form-control', {
                            error: errors && errors.totalInstalment
                          })}
                          {...register('totalInstalment', {
                            required: true,
                            min: minInstalment,
                            max: maxInstalment,
                            validate: {
                              ...(userType === UserTypes.CUSTOMER && {
                                minElectricityInstalment: () => watchElectricityInstalment !== 0
                              }),
                              ...(hasEffectiveGasContract &&
                                userType === UserTypes.CUSTOMER &&
                                typeof watchGasInstalment !== 'undefined' && {
                                  minGasInstalment: () => watchGasInstalment !== 0
                                })
                            }
                          })}
                          onChange={(e) => {
                            setValue('totalInstalment', roundToTwoDecimals(Number(e.target.value)))
                            handleInstalmentChange(FieldTypes.TOTAL, Number(e.target.value))
                          }}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              handleSubmit(onSubmit)()
                            }
                          }}
                          onWheel={(e) => e.currentTarget.blur()}
                        />
                      </div>
                    </div>

                    {billShockAmount !== null && (
                      <div className={styles['billshock-container']}>
                        <span className={styles.label}>{t('instalment.settlement.label')}</span>
                        <div
                          className={classNames(styles.settlement, {
                            [styles['pay-extra']]: billShockAmount > 0
                          })}
                        >
                          {refetchingBillShock ? (
                            <span>
                              <LoadingDots />
                            </span>
                          ) : (
                            <div className={styles['billshock-amount']}>
                              <span>
                                € {formatAmount(Math.abs(billShockAmount))} {isMobile && <br />}
                                {billShockAmount > 0 ? (
                                  <span className={styles['label-recover-pay-extra']}>{t('instalment.settlement.payExtra')}</span>
                                ) : (
                                  <span className={styles['label-recover-pay-extra']}>{t('instalment.settlement.recover')}</span>
                                )}
                              </span>
                              <div>
                                <InfoBadge
                                  type={billShockAmount > 0 ? 'warning' : undefined}
                                  tooltipContent={
                                    <>
                                      {parse(t('instalment.settlement.tooltip.lastUpdate', { date: billShockDate }))}
                                      <br />
                                      <br />
                                      {parse(t(`instalment.settlement.tooltip.description.${isFixedProduct ? 'fix' : 'variable'}`))}
                                      <br />
                                      <br />
                                      <Link to={routes.BILLING_CYCLES}>{t('instalment.settlement.tooltip.link')} &raquo;</Link>
                                    </>
                                  }
                                  tooltipWidth={340}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>

                  {errors && errors.totalInstalment && (
                    <Banner type="blocking" className={styles['alert-container']}>
                      <p className="mb-200">
                        {errors.totalInstalment.type === 'required' && t('required', { ns: 'validation' })}
                        {errors.totalInstalment.type === 'min' &&
                          t('instalment.form.errors.min', {
                            amount: formatAmount(minInstalment)
                          })}
                        {errors.totalInstalment.type === 'max' &&
                          t('instalment.form.errors.max', {
                            amount: formatAmount(maxInstalment)
                          })}
                        {errors.totalInstalment.type === 'minElectricityInstalment' && t('instalment.form.errors.minElectricityInstalment')}
                        {errors.totalInstalment.type === 'minGasInstalment' && t('instalment.form.errors.minGasInstalment')}
                      </p>
                      {(errors.totalInstalment.type === 'max' || errors.totalInstalment.type === 'min') && (
                        <Button
                          destructive
                          size="small"
                          onClick={() =>
                            handleInstalmentChange(FieldTypes.TOTAL, errors.totalInstalment?.type === 'max' ? maxInstalment : minInstalment)
                          }
                        >
                          {t('instalment.form.buttonMobile', {
                            newInstalment: formatAmount(errors.totalInstalment?.type === 'max' ? maxInstalment : minInstalment)
                          })}
                        </Button>
                      )}
                    </Banner>
                  )}

                  {!loading && instalmentSaved && (
                    <Banner type="positive" className={styles['alert-container']}>
                      <div>
                        {parse(
                          t('instalment.form.success', {
                            newInstalment: formatAmount(watchTotalInstalment)
                          })
                        )}
                      </div>
                    </Banner>
                  )}

                  <Button
                    type="submit"
                    disabled={!isValid || currentInstalment === watchTotalInstalment || instalmentSaved}
                    loading={loading}
                  >
                    {isMobile
                      ? t('instalment.form.buttonMobile', {
                          newInstalment: formatAmount(watchTotalInstalment)
                        })
                      : t('instalment.form.button', {
                          newInstalment: formatAmount(watchTotalInstalment)
                        })}
                  </Button>

                  {hasEffectiveGasContract && (
                    <div className={styles['instalments-container']}>
                      <div className={styles['fuel-container']}>
                        <label htmlFor="electricityInstalment">{t('instalment.newElecInstalment')}</label>
                        <div
                          className={classNames(styles['value-container'], {
                            [styles.error]: errors?.totalInstalment?.type === 'minElectricityInstalment'
                          })}
                        >
                          {editElecInstalment ? (
                            <div className="form-group">
                              <div className="prepend-input-wrapper">
                                <span className="prepend">€</span>
                                <input
                                  type="number"
                                  step="0.01"
                                  className={classNames(styles['instalment-input'], 'form-control', {
                                    error: errors?.electricityInstalment
                                  })}
                                  {...register('electricityInstalment', {
                                    min: 1,
                                    required: true,
                                    valueAsNumber: true
                                  })}
                                  onChange={(e) => {
                                    setValue('electricityInstalment', roundToTwoDecimals(Number(e.currentTarget.value)), {
                                      shouldValidate: true
                                    })
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                      setEditElecInstalment(false)
                                      handleInstalmentChange(FieldTypes.FUEL)
                                    }
                                  }}
                                  onWheel={(e) => e.currentTarget.blur()}
                                />
                              </div>
                              <Button
                                representation="link"
                                variant="secondary"
                                size="small"
                                type="button"
                                disabled={!!errors?.electricityInstalment}
                                onClick={() => {
                                  setEditElecInstalment(false)
                                  handleInstalmentChange(FieldTypes.FUEL)
                                }}
                              >
                                {t('confirm', { ns: 'common' }).toLowerCase()}
                              </Button>
                            </div>
                          ) : (
                            <div>
                              <data value={formatAmount(roundToTwoDecimals(Number(watchElectricityInstalment)))} className={styles.value}>
                                € {formatAmount(roundToTwoDecimals(Number(watchElectricityInstalment)))}
                              </data>
                              <Button
                                representation="link"
                                variant="secondary"
                                size="small"
                                onClick={(e) => {
                                  e.preventDefault()
                                  setEditElecInstalment(true)
                                }}
                              >
                                {t('edit', { ns: 'common' }).toLowerCase()}
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className={styles['fuel-container']}>
                        <label htmlFor="gasInstalment">{t('instalment.newGasInstalment')}</label>
                        <div
                          className={classNames(styles['value-container'], {
                            [styles.error]: errors?.totalInstalment?.type === 'minGasInstalment'
                          })}
                        >
                          {editGasInstalment ? (
                            <div className="form-group">
                              <div className="prepend-input-wrapper">
                                <span className="prepend">€</span>
                                <input
                                  type="number"
                                  step="0.01"
                                  className={classNames(styles['instalment-input'], 'form-control', {
                                    error: errors?.gasInstalment
                                  })}
                                  {...register('gasInstalment', {
                                    min: 1,
                                    required: true,
                                    valueAsNumber: true
                                  })}
                                  onChange={(e) => {
                                    setValue('gasInstalment', roundToTwoDecimals(Number(e.target.value)), { shouldValidate: true })
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                      setEditGasInstalment(false)
                                      handleInstalmentChange(FieldTypes.FUEL)
                                    }
                                  }}
                                  onWheel={(e) => e.currentTarget.blur()}
                                />
                              </div>
                              <Button
                                type="button"
                                representation="link"
                                variant="secondary"
                                size="small"
                                disabled={!!errors?.gasInstalment}
                                onClick={() => {
                                  setEditGasInstalment(false)
                                  handleInstalmentChange(FieldTypes.FUEL)
                                }}
                              >
                                {t('confirm', { ns: 'common' }).toLowerCase()}
                              </Button>
                            </div>
                          ) : (
                            <div>
                              <data value={formatAmount(roundToTwoDecimals(Number(watchGasInstalment || 0)))} className={styles.value}>
                                € {formatAmount(roundToTwoDecimals(Number(watchGasInstalment || 0)))}
                              </data>
                              <Button
                                representation="link"
                                variant="secondary"
                                size="small"
                                onClick={(e) => {
                                  e.preventDefault()
                                  setEditGasInstalment(true)
                                }}
                              >
                                {t('edit', { ns: 'common' }).toLowerCase()}
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </>
          )}
        </Card>
      </div>

      {!!instalmentFaqCategory && (
        <FooterFaq
          isLoading={withInstalmentFaqLoading || withoutInstalmentFaqLoading}
          category={instalmentFaqCategory}
          subtitle={t('faq.subtitles.instalment', { ns: 'common' })}
        />
      )}

      {/* FeedbackWidget */}
      {feedbackWidgetData?.isActive && <FeedbackWidget id={feedbackWidgetData._id} openFeedbackWidget={openFeedbackWidget} />}
    </>
  )
}

export default Instalment
