import { AccountingEntity, CustomerAccountingDocument, DocumentType } from 'types/types.ts'
import { filteredInvoicesProps } from 'pages/App/Billing/Invoices/hooks/types.ts'
import { routes } from 'types/routes.ts'
import { InvoiceTypeTranslationKeys } from 'pages/App/Billing/Invoices/constants.ts'
import resources from 'translations/dutch'

/**
 * Returns filtered documents for a given route.
 * @param {invoicesRoute} route
 * @param {CustomerAccountingDocument[]} documents
 */
export const filterDocuments = ({ route, documents }: filteredInvoicesProps) => {
  if (!documents?.length) return []

  switch (route) {
    case routes.BILLING_SUPER_USER:
      return documents
    case routes.BILLING_SUPER_USER_CLIENT:
      return documents.filter((document) => document.accountingEntity === AccountingEntity.CUSTOMER)
    case routes.BILLING_SUPER_USER_DISTRIBUTOR:
      return documents.filter((document) => document.accountingEntity === AccountingEntity.SUPPLIER)
    case routes.BILLING_ALL_INVOICES:
      return documents.filter((document) => document.documentType === DocumentType.INVOICE)
    case routes.BILLING_ALL_TRANSACTIONS:
      return documents.filter((document) => document.documentType === DocumentType.PAYMENT)
    case routes.BILLING_OUTSTANDING_INVOICES:
      return documents.filter((document) => !document.paid && document.accountingEntity === AccountingEntity.CUSTOMER)

    default:
      return documents
  }
}

/**
 * Returns computed data for a given document
 * @param {CustomerAccountingDocument} document
 */
export const getDocumentData = (document: CustomerAccountingDocument) => ({
  isCustomerInvoice: document?.documentType === DocumentType.INVOICE,
  invoiceTypeTranslation:
    document?.documentType === DocumentType.INVOICE &&
    (InvoiceTypeTranslationKeys[document.invoiceType] as unknown as keyof typeof resources.billing.invoiceTypes)
})
