import { CellVariant } from 'pages/App/Billing/Invoices/InvoicesTable/Cell/types.ts'
import { CustomerInvoice } from 'types/types.ts'
import { Button } from '@boltenergy-be/design-system'
import Cell from 'pages/App/Billing/Invoices/InvoicesTable/Cell/Cell.tsx'
import { PaymentButtonProps } from 'pages/App/Billing/Invoices/InvoicesTable/PaymentButton/types.ts'
import { getDocumentData } from 'pages/App/Billing/Invoices/hooks/utils.tsx'
import { useTranslation } from 'react-i18next'
import mixpanel from 'mixpanel-browser'
import { MX_INVOICE_FLOW } from 'constants/trackingIds'

const PaymentButton = ({ document }: PaymentButtonProps) => {
  // i18n
  const { t } = useTranslation('invoices')

  // Constants
  const { isCustomerInvoice } = getDocumentData(document)

  return (
    <Cell variant={CellVariant.PAYMENT_BUTTON}>
      {isCustomerInvoice && !!(document as CustomerInvoice).paymentUrl && !document.paid && (
        <Button
          isFullwidth
          size="small"
          onClick={() => {
            mixpanel.track(MX_INVOICE_FLOW.payInvoice)
            window.open((document as CustomerInvoice).paymentUrl, '_blank')
          }}
        >
          {t('cells.payOnline')}
        </Button>
      )}
    </Cell>
  )
}

export default PaymentButton
