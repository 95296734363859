import { store } from 'store'
import { Language } from 'store/app/types'
import { deleteRedirect } from 'store/app/slice'
import { switchLanguage as switchLanguageAction } from 'store/app/thunks'
import { clearTokens, isAuthenticated } from 'utils/localStorageService'
import { UserTypes } from 'store/auth/types'
import { checkIsSales } from 'utils/sales'
import { routes } from 'types/routes'
import { getSessionUserType, getUserType } from 'utils/userType'
import { USER_TYPE_CONFIG } from 'constants/userTypes'
import { clearUser as clearUserAction } from 'store/user/slice'
import { KNOWN_DOWNTIME_PERIODS } from 'constants/downtime.ts'
import dayjs from 'dayjs'
import { CheckIsDown, SystemDownTypes } from 'types/downtime.ts'
import { SYSTEM_DOWN } from 'constants/envs.ts'
import { IS_DEVELOPMENT_OR_PREVIEW } from 'constants/constants.ts'
import i18next from 'i18next'
import { COMMUNITY_SALES_ACTIONS } from 'constants/referral.ts'
import isBetween from 'dayjs/plugin/isBetween'
import { ActiveActionPeriod } from 'types/referral.ts'

dayjs.extend(isBetween)

/**
 * Handles the clear user action
 */
export const clearUser = () => {
  store.dispatch(clearUserAction())
}

/**
 * Helper function to get the value of a CSS variable
 * @param {string} name
 * @param {string} fallback
 * @returns {string}
 */
export const getCssVariable = (name: string, fallback?: string): string =>
  getComputedStyle(document.documentElement).getPropertyValue(name) || fallback || ''

/**
 * Returns the correct refreshToken URL for the given userType
 *
 * @param {UserTypes} userType
 * @returns {string}
 */
export const getRefreshTokenUrlForUserType = (userType: UserTypes): string => {
  switch (userType) {
    case UserTypes.SALES:
      return '/auth/sales/refresh-token'

    case UserTypes.SUPER_USER:
      return '/auth/internal/refresh-token'

    default:
      return '/auth/refresh-token'
  }
}

/**
 * Handles the logout action
 */
export const logout = (redirectFn: () => void, userType?: UserTypes) => {
  // remove redirect from AppStore if still set
  store.dispatch(deleteRedirect())
  clearTokens(userType || getUserType())
  clearUser()
  redirectFn()
}

/**
 * Handles the switch language action
 */
export const switchLanguage = (language: Language) => {
  store.dispatch(switchLanguageAction({ language }))
}

/**
 * Returns the correct redirect URL for the given userType
 * @param {UserTypes | null} userType
 * @returns {routes.CUSTOMER_EMAIL | routes.SALES_HOME | routes.WELCOME | routes.LOGIN_SALES | routes.LOGIN}
 */
export const getIndexRedirectUrl = (userType: UserTypes | null): routes => {
  const authenticatedUserTypes = isAuthenticated()
  const sessionsUserType = getSessionUserType()
  const userTypeToUse = userType || sessionsUserType
  const isSales = checkIsSales()

  // Get route for the given userType if it's authenticated
  if (userTypeToUse && authenticatedUserTypes[userTypeToUse]) {
    return USER_TYPE_CONFIG[userTypeToUse].home
  }

  if (Object.values(authenticatedUserTypes).some((isAuthenticated) => isAuthenticated)) {
    if (authenticatedUserTypes[UserTypes.SUPER_USER]) return USER_TYPE_CONFIG[UserTypes.SUPER_USER].home
    if (authenticatedUserTypes[UserTypes.SALES]) return USER_TYPE_CONFIG[UserTypes.SALES].home
    if (authenticatedUserTypes[UserTypes.CUSTOMER]) return USER_TYPE_CONFIG[UserTypes.CUSTOMER].home
  }

  return isSales ? USER_TYPE_CONFIG[UserTypes.SALES].login : USER_TYPE_CONFIG[UserTypes.CUSTOMER].login
}

/**
 * Checks if the app is down for maintenance
 * @param {UserTypes} loginPageUserType
 * @returns {CheckIsDown}
 */
export const checkIsDown = (loginPageUserType?: UserTypes): CheckIsDown => {
  const today = new Date()
  const userType = loginPageUserType || getUserType()
  const language = store.getState().app.language

  // Look for a scheduled downtime period
  const activePeriod = KNOWN_DOWNTIME_PERIODS.find((period) => dayjs(today).isBetween(period.start, period.end))

  // Check downtime states
  const isSystemDown = SYSTEM_DOWN && (!userType || userType === UserTypes.CUSTOMER)
  const isMaintenance = !!activePeriod && (!userType || activePeriod.userTypes.includes(userType))
  const isDown = isSystemDown || isMaintenance

  return {
    isDown: !IS_DEVELOPMENT_OR_PREVIEW && isDown,
    message: isSystemDown
      ? i18next.t('systemDown', { ns: 'common' })
      : activePeriod?.customMessage?.[language] || i18next.t('downForMaintenance', { ns: 'common' }),
    type: isSystemDown ? SystemDownTypes.UNKNOWN : SystemDownTypes.MAINTENANCE
  }
}

/**
 * Returns the active community sales action period
 * @returns {ActiveActionPeriod | undefined}
 */
export const getActiveCommunitySalesActionPeriod = (): ActiveActionPeriod | undefined => {
  return COMMUNITY_SALES_ACTIONS.find((action) => dayjs().isBetween(action.start, action.end))
}
