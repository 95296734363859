import InvoicesTable from 'pages/App/Billing/Invoices/InvoicesTable/InvoicesTable.tsx'
import useInvoicesTable from 'pages/App/Billing/Invoices/hooks/useInvoicesTable.tsx'
import { routes } from 'types/routes.ts'
import NoInvoices from 'pages/App/Billing/Invoices/NoInvoices/NoInvoices.tsx'
import mixpanel from 'mixpanel-browser'
import { useEffect } from 'react'
import { MX_INVOICE_FLOW } from 'constants/trackingIds'

const OutstandingInvoices = () => {
  // hooks
  const { headers, rows } = useInvoicesTable({ route: routes.BILLING_OUTSTANDING_INVOICES })

  useEffect(() => {
    mixpanel.track(MX_INVOICE_FLOW.overview)
  }, [])

  return rows.length ? <InvoicesTable headers={headers} rows={rows} /> : <NoInvoices route={routes.BILLING_OUTSTANDING_INVOICES} />
}

export default OutstandingInvoices
